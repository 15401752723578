import axios from 'axios';
import db from '../stores/db';
import { v4 as uuidv4 } from 'uuid';
import collegeTeamsLookup from '../stores/collegeTeamsLookup';
import { setLastFetchTime } from '../stores/dbQueries';

const baseURL = process.env.NODE_ENV === 'production' ? 'https://ogzaniacpicks.com' : 'http://localhost:5000';

export const getCBBData = async () => {
	const oddstraderData = await fetchOddsTraderData();
	const dunkelIndexData = await fetchDunkelIndexData();
	const cbsData = await fetchCBSSportsData();

	// SAFELY INITIALIZE EVENTS AND PICKS ARRAYS
	const events = [...oddstraderData.events, ...dunkelIndexData.events, ...cbsData.events];
	const picks = [...oddstraderData.picks, ...dunkelIndexData.picks, ...cbsData.picks];

	for (const event of events) {
		// INITIALIZE COMPETITIONS OBJECT TO AVOID NULL ERRORS
		const competitions = event.competitions || {};
		event.competitions = competitions;

		// NULL-CHECKING AND SETTING AWAY TEAM DATA
		const awayTeam = competitions.awayTeam || {};
		if (awayTeam.id) {
			const awayTeamRes = await getCBBTeamRecordByTeamId(awayTeam.id);
			const awayTeamData = {
				...awayTeam,
				record: awayTeamRes?.team?.record || null,
				standingSummary: awayTeamRes?.team?.standingSummary || null,
			};
			event.competitions.awayTeam = awayTeamData;
		} else {
			event.competitions.awayTeam = awayTeam;
		}

		// NULL-CHECKING AND SETTING HOME TEAM DATA
		const homeTeam = competitions.homeTeam || {};
		if (homeTeam.id) {
			const homeTeamRes = await getCBBTeamRecordByTeamId(homeTeam.id);
			const homeTeamData = {
				...homeTeam,
				record: homeTeamRes?.team?.record || null,
				standingSummary: homeTeamRes?.team?.standingSummary || null,
			};
			event.competitions.homeTeam = homeTeamData;
		} else {
			event.competitions.homeTeam = homeTeam;
		}
	}

	// FILTER OUT EVENTS OR PICKS WITHOUT A VALID ID BEFORE BULK PUTTING
	const validEvents = events.filter((event) => event.id);
	const validPicks = picks.filter((pick) => pick.id);

	// LOG INVALID ITEMS FOR DEBUGGING IF NEEDED
	if (validEvents.length < events.length) {
		console.warn(
			'Some events are missing an ID and were not saved:',
			events.filter((event) => !event.id)
		);
	}
	if (validPicks.length < picks.length) {
		console.warn(
			'Some picks are missing an ID and were not saved:',
			picks.filter((pick) => !pick.id)
		);
	}

	validEvents.forEach((event) => {
		event.league = 'cbb';
	});

	await db.events.bulkPut(validEvents);
	await db.picks.bulkPut(validPicks);

	await setLastFetchTime('cbb');

	return { events: validEvents, picks: validPicks };
};

async function fetchOddsTraderData() {
	const fetchURL = `${baseURL}/api/scrape/cbb/oddstrader/picks`;
	const events = [];
	const picks = [];

	try {
		const response = await axios.get(fetchURL);
		console.log(
			'%cCBB | FETCHING ODDSTRADER',
			'background: #d3d3d3; color: black; font-weight: bold; border-radius: 5px; padding: 2px 6px; font-size: 11px;',
			'\n',
			response.data
		);

		response.data.forEach((item) => {
			if (item.matchedTeamNextEvent) events.push(item.matchedTeamNextEvent);

			picks.push({
				id: item.id || uuidv4(),
				eventId: item.matchedTeamNextEvent?.id || null,
				source: 'Oddstrader',
				league: 'cbb',
				pickValue: item.pickValue || null,
				teamId: item.matchedTeam?.id || null,
				analysis: item.analysis || null,
				pickLabel: item.pickLabel || 'Oddstrader Pick',
				pickSourceURL: item.pickSourceURL || null,
				pickTeamLogo: item.imageUrl || null,
				matchedTeam: item.matchedTeam || null,
				matchedTeamNextEvent: item.matchedTeamNextEvent || null,
			});
		});
	} catch (error) {
		console.error('Error fetching Oddstrader:', error);
	}

	return { events, picks };
}

async function fetchDunkelIndexData() {
	const fetchURL = `${baseURL}/api/scrape/cbb/dunkelIndex/picks`;
	const events = [];
	const picks = [];

	try {
		const response = await axios.get(fetchURL);
		// console.log('FETCH DUNKEL INDEX \n', response);
		console.log(
			'%cCBB | FETCHING DUNKEL INDEX',
			'background: #d3d3d3; color: black; font-weight: bold; border-radius: 5px; padding: 2px 6px; font-size: 11px;',
			'\n',
			response.data
		);

		response.data.forEach((item) => {
			if (item.matchedTeamNextEvent) events.push(item.matchedTeamNextEvent);

			picks.push({
				id: item.id || uuidv4(),
				eventId: item.matchedTeamNextEvent?.id || null,
				source: 'Dunkel Index',
				league: 'cbb',
				pickValue: item.pickValue || null,
				teamId: item.matchedTeam?.id || null,
				analysis: item.analysis,
				pickLabel: item.pickLabel || 'Dunkel Pick',
				pickSourceURL: item.pickSourceURL || null,
				pickTeamLogo: item.imageUrl || null,
				matchedTeam: item.matchedTeam || null,
				matchedTeamNextEvent: item.matchedTeamNextEvent || null,
			});
		});
	} catch (error) {
		console.error('Error fetching Dunkel Index:', error);
		if (error.response) {
			console.error(`Server responded with status ${error.response.status}: ${error.response.data.error}`);
		} else if (error.request) {
			console.error('No response received from server:', error.request);
		} else {
			console.error('Unexpected error:', error.message);
		}
	}

	return { events, picks };
}

async function fetchCBSSportsData() {
	const fetchURL = `${baseURL}/api/scrape/cbb/cbs/picks`;
	const events = [];
	const picks = [];

	try {
		const response = await axios.get(fetchURL);
		console.log(
			'%cCBB | FETCHING CBS SPORTS',
			'background: #d3d3d3; color: black; font-weight: bold; border-radius: 5px; padding: 2px 6px; font-size: 11px;',
			'\n',
			response.data
		);

		response.data.forEach((item) => {
			if (item.matchedTeamNextEvent) events.push(item.matchedTeamNextEvent);

			picks.push({
				id: item.id || uuidv4(),
				eventId: item.matchedTeamNextEvent?.id || null,
				source: 'CBS',
				league: 'cbb',
				pickValue: item.pickValue || null,
				teamId: item.matchedTeam?.id || null,
				analysis: item.analysis || null,
				pickLabel: item.pickLabel || 'CBS Pick',
				pickSourceURL: item.pickSourceURL || null,
				pickTeamLogo: item.pickTeamLogo || null,
				matchedTeam: item.matchedTeam || null,
				matchedTeamNextEvent: item.matchedTeamNextEvent || null,
				meta: item.meta || [],
			});
		});
	} catch (error) {
		console.error(`Error fetching or parsing CBS CBB data: ${error.message}`, error.stack);
		if (error.response) {
			console.error(`Server responded with status ${error.response.status}: ${error.response.data.error}`);
		} else if (error.request) {
			console.error('No response received from server:', error.request);
		} else {
			console.error('Unexpected error:', error.message);
		}
	}

	return { events, picks };
}

async function getCBBTeamRecordByTeamId(teamId) {
	const url = `https://site.api.espn.com/apis/site/v2/sports/basketball/mens-college-basketball/teams/${teamId}`;

	try {
		const data = await axios.get(url);
		return data.data;
	} catch (error) {
		console.error(`Error fetching team details for team ID ${teamId}:`, error);
		return null;
	}
}

export const fetchBoxScoreByEventId = async (eventId) => {
	const fetchURL = `https://site.api.espn.com/apis/site/v2/sports/basketball/mens-college-basketball/summary?event=${eventId}`;
	try {
		const response = await axios.get(fetchURL);

		// const boxscoreModel = {
		// 	id: eventId,
		// }
		return response.data;
	} catch (error) {
		console.error('Error fetching event boxscore:', error);
		return null;
	}
};

export const updateEventStatuses = async (league) => {
	try {
		// Retrieve all events for the league from the database
		const events = await db.events.where('league').equals(league.toLowerCase()).toArray();

		if (!events || events.length === 0) {
			console.warn(`No events found for league ${league}.`);
			return;
		}

		// For each event, fetch the latest status and update it in the database
		await Promise.all(
			events.map(async (event) => {
				try {
					// Fetch the latest status for the event
					const url = `https://site.api.espn.com/apis/site/v2/sports/basketball/mens-college-basketball/summary?event=${event.id}`;
					const response = await axios.get(url);
					const data = response.data;

					// Extract the status from the response
					const status = data.header?.competitions?.[0]?.status;

					if (status) {
						// Update the status field of the event in the database
						await db.events.update(event.id, { status });

						console.log(`Updated status for event ID ${event.id}.`);
					} else {
						console.warn(`No status data for event ID ${event.id}.`);
					}
				} catch (error) {
					console.error(`Error updating status for event ID ${event.id}:`, error);
				}
			})
		);

		console.log(`Event statuses updated for league ${league}.`);
	} catch (error) {
		console.error(`Error updating event statuses for league ${league}:`, error);
	}
};

export const saveKenpomData = async (kenpomData) => {
	if (!kenpomData || kenpomData.length === 0) {
		console.error('NO KENPOM DATA PROVIDED TO SAVE.');
		return;
	}

	try {
		await db.kenpom.clear();
		console.log('EXISTING KENPOM DATA CLEARED.');

		const enrichedData = kenpomData
			.map((item) => {
				try {
					const dataString = JSON.stringify(item);
					return {
						league: 'cbb',
						data: dataString,
					};
				} catch (error) {
					console.error('FAILED TO SERIALIZE KENPOM ENTRY:', item, error);
					return null;
				}
			})
			.filter(Boolean);

		await db.kenpom.bulkPut(enrichedData);
		console.log('KENPOM DATA SAVED TO DATABASE:', enrichedData);
	} catch (error) {
		console.error('ERROR SAVING KENPOM DATA:', error);
	}
};

export const processKenpom = async (htmlString) => {
	if (!htmlString) {
		console.error('NO HTML STRING PROVIDED.');
		return [];
	}

	const titleMap = {
		rank: 'Rank',
		team: 'Team Name',
		conference: 'Conference',
		record: 'Win-Loss Record',
		netRtg: 'Adjusted efficiency margin',
		oRtg: 'Adjusted offensive efficiency: points scored per 100 possessions (adjusted for opponent)',
		dRtg: 'Adjusted defensive efficiency: points allowed per 100 possessions (adjusted for opponent)',
		adjT: 'Adjusted tempo: possessions per 40 minutes (adjusted for opponent)',
		luck: 'Luck rating',
		strengthOfSchedule: 'Strength of schedule rating',
		sosNetRtg: 'Strength of schedule - Net Rating',
		sosORtg: 'Average AdjOE of opposing offenses',
		sosDRtg: 'Average AdjDE of opposing defenses',
		ncsosNetRtg: 'Non-conference strength of schedule rating',
	};

	const normalizeTeamName = (teamName) => {
		const replacements = {
			'A&M': 'am',
			Indy: 'indianapolis',
			// Saint: 'st',
			"St. John's": 'Saint Johns',
			"Saint Mary's": 'Saint Marys',
			Connecticut: 'uconn',
			'N.C. State': 'NC State',
			'Miami FL': 'Miami',
			"Saint Joseph's": 'Saint Josephs',
			'St. Bonaventure': 'Saint Bonaventure',
			"St. John's": 'Saint Johns',
			Memphis: 'Memphis Tigers',
		};

		return teamName
			.split(' ')
			.map((word) => replacements[word] || word.replace(/^St\./, 'State'))
			.join(' ');
	};

	const getTeamIds = async (teamName, teamConference) => {
		const normalizedTeamName = normalizeTeamName(teamName);
		const slug = normalizedTeamName.toLowerCase().replace(/ /g, '-');
		const upperCaseTeamConference = teamConference.toUpperCase();

		console.log(`\n\n\ngetTeamIds( ${teamName} => ${normalizedTeamName} => '${slug}' )`);

		// Match based on slug
		const slugMatches = Object.keys(collegeTeamsLookup).filter((key) => key.includes(slug));

		if (slugMatches.length === 1) {
			const matchedTeam = collegeTeamsLookup[slugMatches[0]];
			if (matchedTeam?.groups?.conference?.abbreviation?.toUpperCase() === upperCaseTeamConference) {
				console.log(`SINGLE MATCH WITH CONFERENCE VALIDATION\n`, matchedTeam);
				return [matchedTeam.id];
			}
		}

		if (slugMatches.length > 1) {
			const matchedTeams = slugMatches.map((key) => collegeTeamsLookup[key]);
			const matchedConferenceTeam = matchedTeams.find(
				(team) => team?.groups?.conference?.abbreviation?.toUpperCase() === upperCaseTeamConference
			);

			if (matchedConferenceTeam) {
				console.log(`MULTIPLE SLUG MATCHES, FOUND CONFERENCE MATCH\n`, matchedConferenceTeam);
				return [matchedConferenceTeam.id];
			}
			console.log(`MULTIPLE SLUG MATCHES, BUT NO CONFERENCE MATCH\n`, matchedTeams);
		}

		// Fallback: Match based on location
		const locationMatches = Object.values(collegeTeamsLookup).filter((team) => {
			const normalizedLocation = team.location?.toLowerCase().replace(/ /g, '-');
			const isConferenceMatch = team?.groups?.conference?.abbreviation?.toUpperCase() === upperCaseTeamConference;
			return normalizedLocation && normalizedTeamName.includes(normalizedLocation) && isConferenceMatch;
		});

		if (locationMatches.length > 0) {
			console.log(`LOCATION MATCHES WITH CONFERENCE VALIDATION\n`, locationMatches);
			return locationMatches.map((team) => team.id);
		}

		// Log unmatched cases for debugging
		console.warn(`NO MATCHES FOUND FOR: ${teamName} (${teamConference})`);
		return [];
	};

	try {
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlString, 'text/html');
		const table = doc.querySelector('#ratings-table');

		if (!table) {
			console.error('NO TABLE WITH ID="ratings-table" FOUND.');
			return [];
		}

		const rows = Array.from(table.querySelectorAll('tbody tr')).map((row) => {
			const cells = Array.from(row.querySelectorAll('td')).map((cell) => cell.textContent.trim());
			const ranks = Array.from(row.querySelectorAll('td span.seed')).map((span) => parseInt(span.textContent, 10));
			return { cells, ranks };
		});

		const unmatchedTeams = [];

		const structuredData = await Promise.all(
			rows.map(async ({ cells, ranks }) => {
				const teamName = cells[1];
				const teamConference = cells[2];
				const matchedTeamIds = await getTeamIds(teamName, teamConference);

				if (matchedTeamIds.length === 0) {
					unmatchedTeams.push({
						teamName,
						conference: teamConference,
						record: cells[3],
						normalizedName: normalizeTeamName(teamName),
					});
				}

				return {
					rank: Math.floor(parseFloat(cells[0])),
					team: teamName,
					matchedTeamIds,
					conference: teamConference,
					record: cells[3],
					netRtg: parseFloat(cells[4]),
					oRtg: {
						value: parseFloat(cells[5]),
						rank: ranks[0],
						title: titleMap.oRtg,
						label: 'ORtg',
					},
					dRtg: {
						value: parseFloat(cells[7]),
						rank: ranks[1],
						title: titleMap.dRtg,
						label: 'DRtg',
					},
					adjT: {
						value: parseFloat(cells[9]),
						rank: ranks[2],
						title: titleMap.adjT,
						label: 'AdjT',
					},
					luck: {
						value: parseFloat(cells[11]),
						rank: ranks[3],
						title: titleMap.luck,
						label: 'Luck',
					},
					strengthOfSchedule: {
						netRtg: parseFloat(cells[13]),
						oRtg: parseFloat(cells[14]),
						dRtg: parseFloat(cells[16]),
						title: titleMap.strengthOfSchedule,
						label: 'SoS',
					},
					ncsosNetRtg: {
						value: parseFloat(cells[18]),
						rank: ranks[4],
						title: titleMap.ncsosNetRtg,
						label: 'ncSoS NetRtg',
					},
				};
			})
		);

		if (unmatchedTeams.length > 0) {
			console.warn('UNMATCHED TEAMS:', unmatchedTeams);
		}

		return structuredData;
	} catch (error) {
		console.error('ERROR PROCESSING TABLE HTML:', error.message);
		return [];
	}
};

export const processAndSaveKenpom = async (htmlString) => {
	const kenpomData = await processKenpom(htmlString);

	if (!kenpomData || kenpomData.length === 0) {
		console.error('NO KENPOM DATA PROCESSED.');
		return;
	}

	await saveKenpomData(kenpomData);
	console.log('KENPOM DATA PROCESSED AND SAVED.');
};

// const associateKenpomWithEvents = async (kenpomData) => {
// 	if (!kenpomData || kenpomData.length === 0) {
// 		console.error('NO KENPOM DATA TO ASSOCIATE.');
// 		return [];
// 	}

// 	try {
// 		const events = await db.events.where('league').equals('cbb').toArray();

// 		if (!events || events.length === 0) {
// 			console.warn('NO EVENTS FOUND FOR LEAGUE TYPE "cbb".');
// 			return [];
// 		}

// 		const updatedEvents = events.map((event) => {
// 			const homeTeamId = String(event?.competitions?.homeTeam?.id);
// 			const awayTeamId = String(event?.competitions?.awayTeam?.id);

// 			const homeKenpom = kenpomData.find((kp) => kp.matchedTeamIds.includes(homeTeamId));
// 			const awayKenpom = kenpomData.find((kp) => kp.matchedTeamIds.includes(awayTeamId));

// 			return {
// 				...event,
// 				kenpomData: {
// 					home: homeKenpom || null,
// 					away: awayKenpom || null,
// 				},
// 			};
// 		});

// 		await db.events.bulkPut(updatedEvents);
// 		console.log('EVENTS UPDATED WITH KENPOM DATA:', updatedEvents);
// 		return updatedEvents;
// 	} catch (error) {
// 		console.error('ERROR ASSOCIATING KENPOM DATA WITH EVENTS:', error);
// 		return [];
// 	}
// };

// const getTeamIds = (teamName) => {
// 	if (!teamName) return [];

// 	// Normalize team name (e.g., handle common abbreviations or variations)
// 	const normalizeTeamName = (name) => {
// 		const replacements = {
// 			'St.': 'State',
// 			UConn: 'Connecticut',
// 			BYU: 'Brigham Young',
// 		};

// 		return name
// 			.split(' ')
// 			.map((word) => replacements[word] || word)
// 			.join(' ');
// 	};

// 	const normalizedTeamName = normalizeTeamName(teamName);
// 	const slug = normalizedTeamName.toLowerCase().replace(/ /g, '-');

// 	// Look up team IDs (example: collegeTeamsLookup should contain team mappings)
// 	const matches = Object.keys(collegeTeamsLookup).filter((key) => key.includes(slug));

// 	return matches.map((key) => collegeTeamsLookup[key]?.id) || [];
// };

// /////////////////////////////////////////////////////////////////////////////////////////////////

// export const processAndAssociateKenpom = async (htmlString) => {
// 	const kenpomData = processKenpom(htmlString);

// 	if (!kenpomData || kenpomData.length === 0) {
// 		console.error('NO KENPOM DATA PROCESSED.');
// 		return;
// 	}

// 	const result = await associateKenpomWithEvents(kenpomData);

// 	console.log('KENPOM DATA PROCESSED AND ASSOCIATED WITH EVENTS.');
// 	return result;
// };

// export const processKenpom = (htmlString) => {
// 	if (!htmlString) {
// 		console.error('NO HTML STRING PROVIDED.');
// 		return;
// 	}

// 	const titleMap = {
// 		rank: 'Rank',
// 		team: 'Team Name',
// 		conference: 'Conference',
// 		record: 'Win-Loss Record',
// 		netRtg: 'Adjusted efficiency margin',
// 		oRtg: 'Adjusted offensive efficiency: points scored per 100 possessions (adjusted for opponent)',
// 		dRtg: 'Adjusted defensive efficiency: points allowed per 100 possessions (adjusted for opponent)',
// 		adjT: 'Adjusted tempo: possessions per 40 minutes (adjusted for opponent)',
// 		luck: 'Luck rating',
// 		strengthOfSchedule: 'Strength of schedule rating',
// 		sosNetRtg: 'Strength of schedule - Net Rating',
// 		sosORtg: 'Average AdjOE of opposing offenses',
// 		sosDRtg: 'Average AdjDE of opposing defenses',
// 		ncsosNetRtg: 'Non-conference strength of schedule rating',
// 	};

// 	const normalizeTeamName = (teamName) => {
// 		const replacements = {
// 			'St.': 'State',
// 			UConn: 'Connecticut',
// 			BYU: 'Brigham Young',
// 		};
// 		return teamName
// 			.split(' ')
// 			.map((word) => replacements[word] || word)
// 			.join(' ');
// 	};

// 	const getTeamIds = (teamName) => {
// 		const normalizedTeamName = normalizeTeamName(teamName);
// 		const slug = normalizedTeamName.toLowerCase().replace(/ /g, '-');
// 		const matches = Object.keys(collegeTeamsLookup).filter((key) => key.includes(slug));
// 		return matches.length > 0 ? matches.map((key) => collegeTeamsLookup[key]?.id) : '';
// 	};

// 	try {
// 		const parser = new DOMParser();
// 		const doc = parser.parseFromString(htmlString, 'text/html');
// 		const table = doc.querySelector('#ratings-table');

// 		if (!table) {
// 			console.error('NO TABLE WITH ID="ratings-table" FOUND.');
// 			return;
// 		}

// 		const rows = Array.from(table.querySelectorAll('tbody tr'))
// 			.map((row) => {
// 				const cells = Array.from(row.querySelectorAll('td')).map((cell) => cell.textContent.trim());
// 				const ranks = Array.from(row.querySelectorAll('td span.seed')).map((span) => parseInt(span.textContent, 10));
// 				return { cells, ranks };
// 			})
// 			.filter(({ cells }) => cells.length > 0);

// 		const structuredData = rows.map(({ cells, ranks }) => {
// 			const teamName = cells[1];
// 			const matchedTeamIds = getTeamIds(teamName);

// 			return {
// 				rank: parseInt(cells[0], 10).toString(),
// 				team: teamName,
// 				matchedTeamIds,
// 				conference: cells[2],
// 				record: cells[3],
// 				netRtg: parseFloat(cells[4]),
// 				oRtg: {
// 					value: parseFloat(cells[5]),
// 					rank: ranks[0],
// 					title: titleMap.oRtg,
// 					label: 'ORtg',
// 				},
// 				dRtg: {
// 					value: parseFloat(cells[7]),
// 					rank: ranks[1],
// 					title: titleMap.dRtg,
// 					label: 'DRtg',
// 				},
// 				adjT: {
// 					value: parseFloat(cells[9]),
// 					rank: ranks[2],
// 					title: titleMap.adjT,
// 					label: 'AdjT',
// 				},
// 				luck: {
// 					value: parseFloat(cells[11]),
// 					rank: ranks[3],
// 					title: titleMap.luck,
// 					label: 'Luck',
// 				},
// 				strengthOfSchedule: {
// 					netRtg: parseFloat(cells[13]),
// 					oRtg: parseFloat(cells[14]),
// 					dRtg: parseFloat(cells[16]),
// 					title: titleMap.strengthOfSchedule,
// 					label: 'SoS',
// 				},
// 				ncsosNetRtg: {
// 					value: parseFloat(cells[18]),
// 					rank: ranks[4],
// 					title: titleMap.ncsosNetRtg,
// 					label: 'ncSoS NetRtg',
// 				},
// 			};
// 		});

// 		return structuredData;
// 	} catch (error) {
// 		console.error('ERROR PROCESSING TABLE HTML:', error.message);
// 	}
// };

// const associateKenpomWithEvents = async (kenpomData) => {
// 	if (!kenpomData || kenpomData.length === 0) {
// 		console.error('NO KENPOM DATA TO ASSOCIATE.');
// 		return [];
// 	}

// 	try {
// 		// FETCH EVENTS WHERE leagueType IS "cbb"
// 		const events = await db.events.where('league').equals('cbb').toArray();

// 		if (!events || events.length === 0) {
// 			console.warn('NO EVENTS FOUND FOR LEAGUE TYPE "cbb".');
// 			return [];
// 		}

// 		// console.log('FETCHED EVENTS:', events);

// 		// MAP KENPOM DATA TO EVENTS
// 		const updatedEvents = events.map((event) => {
// 			const homeTeamId = event?.competitions?.homeTeam?.id;
// 			const awayTeamId = event?.competitions?.awayTeam?.id;

// 			if (!homeTeamId || !awayTeamId) {
// 				console.warn(`MISSING TEAM IDs FOR EVENT ID: ${event.id}`, event);
// 				return event; // SKIP IF TEAM IDS ARE MISSING
// 			}

// 			const homeKenpom = kenpomData.find((kp) => kp.matchedTeamIds.includes(homeTeamId));
// 			const awayKenpom = kenpomData.find((kp) => kp.matchedTeamIds.includes(awayTeamId));

// 			if (!homeKenpom && !awayKenpom) {
// 				console.warn(`NO KENPOM DATA MATCH FOR EVENT ID: ${event.id}`, event);
// 			}

// 			return {
// 				...event,
// 				kenpomData: {
// 					home: homeKenpom || null,
// 					away: awayKenpom || null,
// 				},
// 			};
// 		});

// 		// UPDATE DATABASE WITH ASSOCIATED KENPOM DATA
// 		await db.events.bulkPut(updatedEvents);

// 		console.log(
// 			'%cPUT',
// 			'background: #0078F0; color: white; font-weight: bold; border-radius: 5px; padding: 2px 6px; font-size: 11px;',
// 			'EVENTS ASSOCIATED WITH KENPOM DATA\n',
// 			updatedEvents
// 		);

// 		return updatedEvents;
// 	} catch (error) {
// 		console.error('ERROR ASSOCIATING KENPOM DATA WITH EVENTS:', error);
// 		return [];
// 	}
// };

/* ****************************************************************************************************
BUILD CBB TEAMS LOOKUP
* BASED ON ESPN API: https://site.api.espn.com/apis/site/v2/sports/basketball/mens-college-basketball/teams/[TEAM_ID]
* PREVIOUSLY RAN FROM [0, 6000] WITH ~1342 RESULTS
**************************************************************************************************** */
// const buildCBBTeamsLookup = (async () => {
// 	const teamsLookup = {};
// 	// const unlistedConferences = [];

// 	for (let id = 0; id <= 6000; id++) {
// 		try {
// 			const url = `https://site.api.espn.com/apis/site/v2/sports/basketball/mens-college-basketball/teams/${id}`;
// 			const { data } = await axios.get(url);

// 			// CHECK IF THE API RESPONSE INDICATES A FAILURE
// 			if (data.code === 400 && data.message === 'Failed to get league teams summary') {
// 				console.log('Reached end of available team IDs. Stopping...');
// 				break;
// 			}

// 			const groupConferenceLookup = {
// 				16: {
// 					id: '16',
// 					name: 'Mid-Eastern Athletic',
// 					abbreviation: 'MEAC',
// 					logo: 'https://loodibee.com/wp-content/uploads/Mid-Eastern-Athletic-Conference-MEAC-logo-300x300.png',
// 				},
// 				26: {
// 					id: '26',
// 					name: 'Southwestern Athletic - East',
// 					abbreviation: 'SWAC',
// 					logo: 'https://a2.espncdn.com/combiner/i?img=%2Fi%2Fteamlogos%2Fncaa_conf%2F500%2F31.png',
// 				},
// 				46: {
// 					id: '46',
// 					name: 'Atlantic Sun',
// 					abbreviation: 'ASUN',
// 					logo: 'https://static.wikia.nocookie.net/nba/images/c/c4/Atlantic_Sun_Conference.png/revision/latest?cb=20221014005455',
// 				},
// 				18: {
// 					id: '18',
// 					name: 'Missouri Valley',
// 					abbreviation: 'MVC',
// 					logo: 'https://a.espncdn.com/i/teamlogos/ncaa_conf/sml/trans/missouri_valley_football.gif',
// 				},
// 				30: {
// 					id: '30',
// 					name: 'Western Athletic',
// 					abbreviation: 'WAC',
// 					logo: 'https://gray-kbtx-prod.gtv-cdn.com/resizer/v2/KUSVUHNO2ZBJTCOBVKNIAWRAOM.png?auth=d38b571a180c34ddf5af0fbb34ff21b81709f728751c5ef3623cac3f27a92551&width=1200&height=600&smart=true',
// 				},
// 				6: {
// 					id: '6',
// 					name: 'Big South',
// 					abbreviation: 'BSTH',
// 					logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Big_South_Conference_icon.svg/506px-Big_South_Conference_icon.svg.png',
// 				},
// 				13: {
// 					id: '13',
// 					name: 'Metro Atlantic Athletic',
// 					abbreviation: 'MAAC',
// 					logo: 'https://s.secure.espncdn.com/stitcher/image.png?height=200&width=200&source=https://artwork.espncdn.com/categories/8c517dfb-80ed-4518-aa52-8703b1ccd788/1x1headerLogo/original_201902251514.svg',
// 				},
// 				14: {
// 					id: '14',
// 					name: 'Mid-American',
// 					abbreviation: 'MAC',
// 					logo: 'https://a4.espncdn.com/combiner/i?img=%2Fi%2Fteamlogos%2Fncaa_conf%2F500%2F15.png',
// 				},
// 				19: {
// 					id: '19',
// 					name: 'Northeast',
// 					abbreviation: 'NEC',
// 					logo: 'https://a.espncdn.com/combiner/i?img=%2Fi%2Fteamlogos%2Fncaa_conf%2F500%2F25.png',
// 				},
// 				20: {
// 					id: '20',
// 					name: 'Ohio Valley',
// 					abbreviation: 'OVC',
// 					logo: 'https://a.espncdn.com/i/teamlogos/ncaa_conf/sml/trans/ohio_valley.gif',
// 				},
// 				24: {
// 					id: '24',
// 					name: 'Southern',
// 					abbreviation: 'SC',
// 					logo: 'https://a4.espncdn.com/combiner/i?img=%2Fi%2Fteamlogos%2Fncaa_conf%2F500%2F29.png',
// 				},
// 				25: {
// 					id: '25',
// 					name: 'Southland',
// 					abbreviation: 'SLND',
// 					logo: 'https://a.espncdn.com/i/teamlogos/ncaa_conf/sml/trans/southland.gif',
// 				},
// 				29: {
// 					id: '29',
// 					name: 'West Coast',
// 					abbreviation: 'WCC',
// 					logo: 'https://a.espncdn.com/i/teamlogos/ncaa_conf/500/west_coast.png',
// 				},
// 				45: {
// 					id: '45',
// 					name: 'Horizon',
// 					abbreviation: 'HORZ',
// 					logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlvHufgmop_copfOPgeUcQ1lsRCq5SyVlfQw&s',
// 				},
// 				49: {
// 					id: '49',
// 					name: 'Summit League',
// 					abbreviation: 'SUM',
// 					logo: 'https://s.secure.espncdn.com/stitcher/image.png?height=200&width=200&source=https://artwork.espncdn.com/categories/07582d6b-9a7f-425e-8eb6-054495b14f6c/1x1headerLogo/original_201902251531.svg',
// 				},
// 				1: {
// 					id: 1,
// 					name: 'America East',
// 					abbreviation: 'AE',
// 					logo: 'https://play-lh.googleusercontent.com/9j8zF2qiueih1LJKuogD4zKp8Pt69QVUuM3RJ8Ir9GNHBYrFMuJk-Jws0rhfgcDdv9Q=w240-h480-rw',
// 				},
// 				2: {
// 					id: 2,
// 					name: 'Atlantic Coast',
// 					abbreviation: 'ACC',
// 					logo: 'https://a.espncdn.com/combiner/i?img=/i/teamlogos/ncaa_conf/500/acc.png?w=50&h=50&transparent=true',
// 				},
// 				3: {
// 					id: 3,
// 					name: 'Atlantic 10',
// 					abbreviation: 'A10',
// 					logo: 'https://static.wikia.nocookie.net/nba/images/7/73/Atlantic_10_Conference_Logo.png/revision/latest/scale-to-width-down/175?cb=20221014004215',
// 				},
// 				4: {
// 					id: 4,
// 					name: 'Big East',
// 					abbreviation: 'BE',
// 					logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXN2bJ0d_Db8w0NcjIc89GF7vlYN59hDVxGw&s',
// 				},
// 				5: {
// 					id: 5,
// 					name: 'Big Sky Conference',
// 					abbreviation: 'BSky',
// 					logo: 'https://a.espncdn.com/i/teamlogos/ncaa_conf/sml/trans/big_sky.gif',
// 				},
// 				7: {
// 					id: 7,
// 					name: 'Big Ten Conference',
// 					abbreviation: 'B10',
// 					logo: 'https://a.espncdn.com/combiner/i?img=/i/teamlogos/ncaa_conf/500/big_ten.png?w=50&h=50&transparent=true',
// 				},
// 				8: {
// 					id: 8,
// 					name: 'Big 12 Conference',
// 					abbreviation: 'B12',
// 					logo: 'https://a.espncdn.com/combiner/i?img=/i/teamlogos/ncaa_conf/500/big_12.png?w=50&h=50&transparent=true',
// 				},
// 				9: {
// 					id: 9,
// 					name: 'Big Sky Conference',
// 					abbreviation: 'BSKY',
// 					logo: 'https://a.espncdn.com/i/teamlogos/ncaa_conf/sml/trans/big_sky.gif',
// 				},
// 				10: {
// 					id: 10,
// 					name: 'Coastal Athletic Association',
// 					abbreviation: 'CAA',
// 					logo: 'https://static.wikia.nocookie.net/nba/images/7/71/Colonial_Athletic_Association.png/revision/latest?cb=20221014010123',
// 				},
// 				11: {
// 					id: 11,
// 					name: 'Conference USA',
// 					abbreviation: 'CUSA',
// 					logo: 'https://a.espncdn.com/combiner/i?img=/i/teamlogos/ncaa_conf/500/conference_usa.png?w=50&h=50&transparent=true',
// 				},
// 				12: {
// 					id: 12,
// 					name: 'Ivy League',
// 					abbreviation: 'IVY',
// 					logo: 'https://a.espncdn.com/i/teamlogos/ncaa_conf/sml/trans/ivy.gif',
// 				},
// 				22: {
// 					id: 22,
// 					name: 'Patriot League',
// 					abbreviation: 'PL',
// 					logo: 'https://a.espncdn.com/i/teamlogos/ncaa_conf/sml/trans/patriot.gif',
// 				},
// 				23: {
// 					id: 23,
// 					name: 'Southeastern Conference',
// 					abbreviation: 'SEC',
// 					logo: 'https://a.espncdn.com/combiner/i?img=/i/teamlogos/ncaa_conf/500/sec.png?w=50&h=50&transparent=true',
// 				},
// 				27: {
// 					id: 27,
// 					name: 'Sun Belt Conference',
// 					abbreviation: 'SBC',
// 					logo: 'https://a.espncdn.com/combiner/i?img=/i/teamlogos/ncaa_conf/500/sun_belt.png?w=50&h=50&transparent=true',
// 				},
// 				44: {
// 					id: 44,
// 					name: 'Mountain West',
// 					abbreviation: 'MWC',
// 					logo: 'https://a.espncdn.com/combiner/i?img=/i/teamlogos/ncaa_conf/500/mountain_west.png?w=50&h=50&transparent=true',
// 				},
// 				51: {
// 					id: 51,
// 					name: 'Southern Intercollegiate Athletic Conference',
// 					abbreviation: 'SIAC',
// 					logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6d/Southern_Intercollegiate_Athletic_Conference_logo.svg/200px-Southern_Intercollegiate_Athletic_Conference_logo.svg.png',
// 				},
// 				52: {
// 					id: 52,
// 					name: 'Atlantic Sun',
// 					abbreviation: 'ASUN',
// 					logo: 'https://static.wikia.nocookie.net/nba/images/c/c4/Atlantic_Sun_Conference.png/revision/latest?cb=20221014005455',
// 				},
// 				62: {
// 					id: 62,
// 					name: 'American Athletic Conference',
// 					abbreviation: 'AAC',
// 					logo: 'https://a.espncdn.com/combiner/i?img=/i/teamlogos/ncaa_conf/500/american.png?w=50&h=50&transparent=true',
// 				},
// 			};

// 			const groupParentDivisionLookup = {
// 				50: {
// 					id: 50,
// 					name: 'NCAA Division I',
// 				},
// 				52: {
// 					id: 52,
// 					name: 'NCAA Division II',
// 				},
// 			};

// 			// const team = data.team;
// 			// const conferenceId = team?.groups?.id;
// 			// const isConference = team?.groups?.isConference;

// 			// HANDLE UNLISTED CONFERENCES
// 			// if (!groupConferenceLookup[conferenceId] && isConference) {
// 			// 	console.log(
// 			// 		`Unlisted Conference | Team: ${team?.displayName} (${team?.slug}) | Team ID: ${team?.id} | Groups:`,
// 			// 		team?.groups
// 			// 	);

// 			// 	unlistedConferences.push({
// 			// 		name: team?.displayName,
// 			// 		slug: team?.slug,
// 			// 		id: team?.id,
// 			// 		groups: team?.groups,
// 			// 	});
// 			// }

// 			const team = data.team;
// 			teamsLookup[team.slug] = {
// 				id: team?.id || null,
// 				uid: team?.uid || null,
// 				slug: team?.slug || null,
// 				name: team?.name || null,
// 				location: team?.location || null,
// 				displayName: team?.displayName || null,
// 				abbreviation: team?.abbreviation || null,
// 				logo: team?.logos?.[0]?.href || null,
// 				groups: {
// 					conference: (team?.groups?.id && groupConferenceLookup[team?.groups?.id]) || team?.groups?.id || '',
// 					division:
// 						(team?.groups?.parent?.id && groupParentDivisionLookup[team?.groups?.parent?.id]) || team?.groups?.parent?.id || '',
// 					isConference: team?.groups?.isConference || '',
// 				},
// 			};

// 			console.log(`${team.id} | ${team.location} ${team.name}`);
// 		} catch (error) {
// 			// console.log(
// 			// 	`${id} | ERROR | https://site.api.espn.com/apis/site/v2/sports/basketball/mens-college-basketball/teams/${id}`,
// 			// 	error.message
// 			// );
// 		}
// 	}

// 	// console.log('Unlisted Conferences:', JSON.stringify(unlistedConferences, null, 2));

// 	console.log(JSON.stringify(teamsLookup, null, 2));

// 	return teamsLookup;
// })();
