import axios from 'axios';
import db from './db';

// FETCH EVENTS BY LEAGUE, FILTER BY STATUS, SORT BY WEEK DESCENDING AND DATE ASCENDING
export const getEventsByLeague = async (league) => {
	try {
		// FETCH EVENTS ONLY FOR SPECIFIED LEAGUE
		const events = await db.events.where('league').equals(league.toLowerCase()).toArray();

		// console.log(`[GET] getEventsByLeague(${league})\n`, events);

		if (events && events.length > 0) {
			console.log(
				'%cGET',
				'background: #218B5A; color: white; font-weight: bold; border-radius: 5px; padding: 2px 6px; font-size: 11px;',
				`getEventsByLeague('${league}')\n`,
				events
			);
		}

		// GET CURRENT STATUS FILTER
		const filterStatus = await getCurrentFilter();

		// FILTER BY STATUS IF FILTER IS SET
		const filteredEvents = filterStatus ? events.filter((event) => event.status?.type?.state === filterStatus) : events;

		// SORT BY MOST RECENT WEEK FIRST AND THEN BY DATE (EARLIEST TO LATEST) WITHIN THE SAME WEEK
		return filteredEvents.sort((a, b) => {
			if (a.week !== b.week) {
				return b.week - a.week; // SORT BY WEEK DESCENDING
			}
			return new Date(a.date) - new Date(b.date); // SORT BY DATE ASCENDING
		});
	} catch (error) {
		console.error(`Error fetching events for league ${league}:`, error);
		return [];
	}
};

export const updateEventsByLeague = async (league, sport, leagueSlug) => {
	try {
		// FETCH EXISTING EVENTS FOR THE LEAGUE
		const events = await getEventsByLeague(league);

		// UPDATE STATUS AND SCOREBOARD FOR EACH EVENT
		const updatedEvents = await Promise.all(
			events.map(async (event) => {
				// const sport = 'basketball';
				// const leagueSlug = 'mens-college-basketball';
				const fetchURL = `http://site.api.espn.com/apis/site/v2/sports/${sport}/${leagueSlug}/summary?event=${event.id}`;

				try {
					const response = await axios.get(fetchURL);
					const fetchedEvent = response.data;

					// if (fetchedEvent.header.id === '401718466') {
					// 	console.log(
					// 		`${event.competitions.awayTeam.displayName} vs ${event.competitions.homeTeam.displayName}\n`,
					// 		fetchedEvent
					// 	);

					// 	const updatedStatus = fetchedEvent.header.competitions[0].status;
					// 	console.log('BEFORE STATUS\n', event.status);
					// 	console.log('UPDATED STATUS\n', updatedStatus);
					// }

					// EXTRACT UPDATED STATUS AND SCOREBOARD FROM FETCHED DATA
					const updatedStatus = fetchedEvent.header.competitions[0].status;
					// const updatedScoreboard = fetchedEvent?.competitions?.[0]?.competitors || event.competitions?.scoreboard;

					// RETURN UPDATED EVENT
					return {
						...event,
						status: { ...updatedStatus },
						statusDisplayText: updatedStatus.type.detail,
						// competitions: {
						// 	...event.competitions,
						// 	scoreboard: updatedScoreboard,
						// },
					};
				} catch (error) {
					console.error(`Error fetching event data for event ${event.id}:`, error);
					return event; // Return the original event if fetch fails
				}
			})
		);

		// DEBUG LOG FOR UPDATED EVENTS
		// const targetEvent = updatedEvents.find((event) => event.id === '401718466');
		console.log(
			'%cPUT',
			'background: #0078F0; color: white; font-weight: bold; border-radius: 5px; padding: 2px 6px; font-size: 11px;',
			`updateEventsByLeague('${league}')\n`,
			updatedEvents
		);

		await db.events.bulkPut(updatedEvents);

		// ADD OR UPDATE LAST FETCHED CBB EVENTS TIME IN METADATA
		const leagueMap = {
			cfb: 'lastFetchedCFBEvents',
			cbb: 'lastFetchedCBBEvents',
		};

		await db.metadata
			.where('key')
			// .equals('lastFetchedCBBEvents')
			.equals(leagueMap[league])
			.and((meta) => meta.league === league)
			.first()
			.then(async (existingMeta) => {
				if (existingMeta) {
					// UPDATE EXISTING ENTRY
					await db.metadata.update(existingMeta.id, {
						value: new Date().toISOString(),
					});
				} else {
					// ADD NEW ENTRY
					await db.metadata.add({
						// key: 'lastFetchedCBBEvents',
						key: leagueMap[league],
						league: league,
						value: new Date().toISOString(),
					});
				}
			});

		// DEBUG LOG FOR UPDATED EVENTS SAVED
		// console.log('Updated Events Saved:', updatedEvents);

		return updatedEvents;
	} catch (error) {
		console.error(`Error updating events for league ${league}:`, error);
		return [];
	}
};

// FETCH PICKS ASSOCIATED WITH A SPECIFIC EVENT
export const getPicksForEvent = async (eventId) => {
	try {
		return await db.picks.where('eventId').equals(eventId).toArray();
	} catch (error) {
		console.error(`Error fetching picks for event ID ${eventId}:`, error);
		return [];
	}
};

// TOGGLE AN EVENT AS A FAVORITE IN THE DATABASE
export const toggleFavoriteEvent = async (eventId) => {
	try {
		const existingFavorite = await db.favorites.where('eventId').equals(eventId).first();
		if (existingFavorite) {
			await db.favorites.delete(existingFavorite.id); // REMOVE IF EXISTS
		} else {
			await db.favorites.add({ eventId }); // ADD IF NOT EXISTS
		}
	} catch (error) {
		console.error(`Error toggling favorite for event ID ${eventId}:`, error);
	}
};

// GET LAST FETCH TIME FOR A SPECIFIC LEAGUE
export const getLastFetchTime = async (leagueKey) => {
	try {
		const metadata = await db.metadata.get({ key: 'fetchedTime', league: leagueKey });
		return metadata ? metadata.value : null;
	} catch (error) {
		console.error(`Error fetching last fetch time for ${leagueKey}:`, error);
		return null;
	}
};

// STORE CURRENT TIME AS LAST FETCH TIME FOR A SPECIFIC LEAGUE IN ISO FORMAT
export const setLastFetchTime = async (leagueKey) => {
	try {
		const fetchedTime = new Date().toISOString();
		const metadataEntry = { key: 'fetchedTime', league: leagueKey, value: fetchedTime };

		// Remove any existing entries with the same key and league
		await db.metadata.where({ key: 'fetchedTime', league: leagueKey }).delete();
		await db.metadata.put(metadataEntry);
	} catch (error) {
		console.error(`Error setting last fetch time for ${leagueKey}:`, error);
	}
};

// SET FILTER IN METADATA
export const setFilter = async (filterType) => {
	try {
		// REMOVE EXISTING FILTER ENTRY
		await db.metadata.where('key').equals('filter').delete();
		// ADD NEW FILTER
		await db.metadata.add({ key: 'filter', value: filterType });
	} catch (error) {
		console.error(`Error setting filter: ${error}`);
	}
};

// SET LEAGUE IN METADATA
export const setLeague = async (leagueName) => {
	try {
		// REMOVE EXISTING LEAGUE ENTRY
		await db.metadata.where('key').equals('league').delete();
		// ADD NEW LEAGUE
		await db.metadata.add({ key: 'league', value: leagueName });
	} catch (error) {
		console.error(`Error setting league: ${error}`);
	}
};

// GET CURRENT FILTER
export const getCurrentFilter = async () => {
	try {
		const filterEntry = await db.metadata.get({ key: 'filter' });
		return filterEntry ? filterEntry.value : null;
	} catch (error) {
		console.error(`Error fetching current filter: ${error}`);
		return null;
	}
};

// GET CURRENT LEAGUE
export const getCurrentLeague = async () => {
	try {
		const leagueEntry = await db.metadata.get({ key: 'league' });
		return leagueEntry ? leagueEntry.value : null;
	} catch (error) {
		console.error(`Error fetching current league: ${error}`);
		return null;
	}
};
