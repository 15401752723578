// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import moment from 'moment';
// ASSETS
import { TbStar, TbStarFilled, TbPlaneDeparture, TbHome, TbFileText, TbExternalLink } from 'react-icons/tb';
import { GrScorecard } from 'react-icons/gr';
import { FaHome, FaPlaneDeparture } from 'react-icons/fa';
import dunkelIndexLogo from '../../assets/img/dunkel-index.svg';
import oddstraderLogo from '../../assets/img/oddstrader.svg';
import coversLogo from '../../assets/img/covers.svg';
import pickswiseLogo from '../../assets/img/pickswise.svg';
import cbsSportsLogo from '../../assets/img/cbs-sports.svg';
// COMPONENTS
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { Button, Spinner, Modal } from 'react-bootstrap';
import Countdown from '../Countdown';
// SERVICES & HELPERS
import db from '../../stores/db';
import { getNFLData, fetchBoxScoreByEventId } from '../../services/nflService';
import { getEventsByLeague, getPicksForEvent, getFavoriteIds, getLastFetchTime, setLastFetchTime } from '../../stores/dbQueries';

const overUnderIconRef = {
	over: 'https://otcdn.virginia.us-east-1.oddstrader.com/bettingOptionIcons/over-icon.svg',
	under: 'https://otcdn.virginia.us-east-1.oddstrader.com/bettingOptionIcons/under-icon.svg',
	push: 'https://otcdn.virginia.us-east-1.oddstrader.com/bettingOptionIcons/push-icon.svg',
};

const logos = {
	Oddstrader: oddstraderLogo,
	Covers: coversLogo,
	Pickswise: pickswiseLogo,
	'Dunkel Index': dunkelIndexLogo,
	CBS: cbsSportsLogo,
	nfl: 'https://static.www.nfl.com/image/upload/v1554321393/league/nvfr7ogywskqrfaiu38m.svg',
	cfb: 'https://upload.wikimedia.org/wikipedia/commons/d/dd/NCAA_logo.svg',
};

const statusPriorityOrder = {
	2: 1,
	1: 2,
	3: 3,
};

const statusLabels = {
	in: 'In Progress',
	pre: 'Scheduled',
	post: 'Completed',
	cancelled: 'Cancelled',
	final: 'Final',
};

const calculateButtonColor = (lastFetchTime) => {
	if (!lastFetchTime) return 'hsl(0, 70%, 50%)';
	const minutesSinceFetch = moment().diff(moment(lastFetchTime), 'minutes');
	const minMinutes = 0;
	const maxMinutes = 60;

	const clampedMinutes = Math.min(Math.max(minutesSinceFetch, minMinutes), maxMinutes);

	const hue = (clampedMinutes * 120) / maxMinutes;

	return `hsl(${hue}, 70%, 50%)`;
};

const NFLDataTable = (props) => {
	const { filters } = props;
	const [isScraping, setIsScraping] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);
	const [favorites, setFavorites] = useState([]);
	const [time, setTime] = useState(null);
	const [buttonColor, setButtonColor] = useState('hsl(120, 70%, 50%)');
	// PICK DESCRIPTION MODAL
	const [selectedPick, setSelectedPick] = useState(null);
	const [showDescriptionModal, setShowDescriptionModal] = useState(false);

	useEffect(() => {
		fetchData();
	}, [filters.status]);

	useEffect(() => {
		if (data.length > 0) {
			console.log('DATA TABLE DATA (NFL)', data);
		}
	}, [data]);

	useEffect(() => {
		const updateButtonColor = () => setButtonColor(calculateButtonColor(time));
		updateButtonColor();

		const interval = setInterval(updateButtonColor, 60000);
		return () => clearInterval(interval);
	}, [time]);

	useEffect(() => {
		if (selectedPick) {
			console.log('SELECTED PICK \n', selectedPick);
		}
	}, [selectedPick]);

	const fetchData = async () => {
		setIsLoading(true);

		const storedLastFetch = await getLastFetchTime('nfl');
		setTime(storedLastFetch);
		setButtonColor(calculateButtonColor(storedLastFetch));

		// const storedEvents = await getEventsByLeague('nfl');
		const storedEvents = await getEventsByLeague('nfl', filters.status);
		// console.log('STORED NFL EVENTS \n', storedEvents);

		const eventsWithPicksAndBoxScores = await Promise.all(
			storedEvents.map(async (event) => {
				const picks = await getPicksForEvent(event.id);
				const boxscoreData = await fetchBoxScoreByEventId(event.id);

				return {
					...event,
					picks,
					boxscore: boxscoreData,
				};
			})
		);

		const favoriteIds = await getFavoriteIds();
		const sortedData = sortEventsByStatusAndFavorites(eventsWithPicksAndBoxScores, favoriteIds);

		setData(sortedData);
		setFavorites(favoriteIds);
		setIsLoading(false);
	};

	const getFavoriteIds = async () => {
		const favorites = await db.favorites.toArray();
		return favorites.map((fav) => fav.eventId);
	};

	const toggleFavoriteEvent = async (eventId) => {
		const existingFavorite = await db.favorites.where('eventId').equals(eventId).first();
		if (existingFavorite) {
			await db.favorites.delete(existingFavorite.id);
		} else {
			await db.favorites.add({ eventId });
		}
	};

	const sortEventsByFavorites = (events, favoriteIds) => {
		return events.sort((a, b) => {
			const aIsFavorite = favoriteIds.includes(a.id);
			const bIsFavorite = favoriteIds.includes(b.id);
			return aIsFavorite === bIsFavorite ? 0 : aIsFavorite ? -1 : 1;
		});
	};

	const handleFavoriteToggle = async (eventId) => {
		await toggleFavoriteEvent(eventId);
		const updatedFavorites = await getFavoriteIds();
		const sortedData = sortEventsByFavorites(data, updatedFavorites);
		setData(sortedData);
		setFavorites(updatedFavorites);

		fetchData();
	};

	const handleRefresh = async () => {
		setIsScraping(true);
		await getNFLData();
		await fetchData();
		setIsScraping(false);
	};

	const sortEventsByStatusAndFavorites = (events, favoriteIds) => {
		return events.sort((a, b) => {
			const aIsFavorite = favoriteIds.includes(a.id);
			const bIsFavorite = favoriteIds.includes(b.id);

			// Prioritize favorites
			if (aIsFavorite !== bIsFavorite) {
				return aIsFavorite ? -1 : 1;
			}

			// If both are favorites or neither, sort by status priority
			const aStatusPriority = statusPriorityOrder[a.status.type.id] || 99;
			const bStatusPriority = statusPriorityOrder[b.status.type.id] || 99;

			return aStatusPriority - bStatusPriority;
		});
	};

	const columns = [
		{
			accessorKey: 'favorite',
			header: '★',
			cell: (info) => {
				const eventId = info.row.original.id;
				const index = info.row.index;

				return (
					<>
						<span className='index'>{index}</span>
						<div className='d-flex flex-column justify-content-center align-items-center ug-2'>
							<Button variant='link' onClick={() => handleFavoriteToggle(eventId)} className='user-select-none'>
								{favorites.includes(eventId) ? (
									<TbStarFilled color={'#FFD101'} strokeWidth={1.6} size={28} />
								) : (
									<TbStar color={'#FFD101'} strokeWidth={1.6} size={28} />
								)}
							</Button>
						</div>
					</>
				);
			},
		},
		{
			accessorKey: 'date',
			header: 'Details',
			size: 500,
			minSize: 50,
			maxSize: 500,
			cell: (info) => {
				const rowData = info.row.original;
				console.log(rowData);
				const date = info.getValue();

				const eventWeekDisplayText = `${rowData.league.toUpperCase()} • Week ${rowData.week} • ${rowData.seasonType}`;
				const boxScoreHeaderCompetitions = rowData.boxscore.header.competitions[0];

				const homeTeamName = boxScoreHeaderCompetitions?.competitors?.[0]?.team?.nickname;
				const awayTeamName = boxScoreHeaderCompetitions?.competitors?.[1]?.team?.nickname;

				const homeTeamLastFiveGames =
					rowData.boxscore.lastFiveGames?.[0].team.displayName === homeTeamName
						? rowData.boxscore.lastFiveGames?.[0]
						: rowData.boxscore.lastFiveGames?.[1];
				const awayTeamLastFiveGames =
					rowData.boxscore.lastFiveGames?.[0].team.displayName === awayTeamName
						? rowData.boxscore.lastFiveGames?.[0]
						: rowData.boxscore.lastFiveGames?.[1];

				const eventBoxScore = {
					awayTeam: {
						linescores: boxScoreHeaderCompetitions?.competitors?.[1] || null,
						score: boxScoreHeaderCompetitions?.competitors?.[1]?.score || 0,
						name: boxScoreHeaderCompetitions?.competitors?.[1]?.team?.nickname || 'N/A',
						teamLogo: boxScoreHeaderCompetitions?.competitors?.[1]?.id
							? `https://a.espncdn.com/i/teamlogos/nfl/500/${boxScoreHeaderCompetitions.competitors[1].id}.png`
							: boxScoreHeaderCompetitions?.competitors?.[1]?.team?.logos?.[1]?.href ||
							  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWdoozTKQWsJeD1CyzzJGe-cA1LUXUAFUrWQ&s',
						lastFiveGames: awayTeamLastFiveGames,
					},
					homeTeam: {
						linescores: boxScoreHeaderCompetitions?.competitors?.[0] || null,
						score: boxScoreHeaderCompetitions?.competitors?.[0]?.score || 0,
						name: boxScoreHeaderCompetitions?.competitors?.[0]?.team?.nickname || 'N/A',
						teamLogo: boxScoreHeaderCompetitions?.competitors?.[0]?.id
							? `https://a.espncdn.com/i/teamlogos/nfl/500/${boxScoreHeaderCompetitions.competitors[0].id}.png`
							: boxScoreHeaderCompetitions?.competitors?.[0]?.team?.logos?.[0]?.href ||
							  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWdoozTKQWsJeD1CyzzJGe-cA1LUXUAFUrWQ&s',
						lastFiveGames: homeTeamLastFiveGames,
					},
					boxscoreData: { ...boxScoreHeaderCompetitions },
				};

				const statusDisplayText = rowData.statusDisplayText;
				const formattedDate = moment(date).format('ddd MM/D');

				// if (rowData.id === '401671661') {
				// 	console.log('EVENT BOXSCORE DATA RAW \n', rowData.boxscore);
				// 	console.log('EVENT BOXSCORE DATA \n', eventBoxScore);
				// }

				// console.log(eventBoxScore);

				const eventVenue = rowData.boxscore.gameInfo.venue;
				const eventVenueImageURL = eventVenue?.images[1]?.href || eventVenue?.images[0]?.href;

				return (
					<div className='details d-flex flex-column ug-1 user-select-none'>
						<p className='fs-xxs text-body-tertiary fw-600 lh-1 text-center'>{eventWeekDisplayText.toUpperCase()}</p>

						<div className='event-boxscore d-flex justify-content-between'>
							<div className='away-team flex-column'>
								<div className='d-flex align-items-center gap-2 fit-content'>
									<img className='w-30' src={eventBoxScore.awayTeam.teamLogo} alt={eventBoxScore.awayTeam.name} />
									<p className='fs-sm'>{eventBoxScore.awayTeam.name}</p>
									<p className='fs-sm fw-600'>{eventBoxScore.awayTeam.score}</p>
								</div>
								{/* {eventBoxScore.awayTeam.lastFiveGames && (
									<div className='d-flex align-items-center gap-1'>
										{eventBoxScore.awayTeam.lastFiveGames.events.map((event) => (
											<>
												<p
													title={`Week ${event.week} ${event.atVs} ${event?.opponent?.displayName} • ${event.score} ${event.gameResult}`}
													className={`d-flex align-items-center justify-content-center w-12 h-12 fs-tiny fw-600 bg-gradient rounded-circle text-white  ${
														event.gameResult === 'W' ? 'bg-success' : 'bg-danger'
													}`}
												>
													{event.gameResult}
												</p>
											</>
										))}
									</div>
								)} */}
							</div>

							{/* <div className='home-team d-flex align-items-center gap-2 fit-content'>
								<p className='fs-sm fw-600'>{eventBoxScore.homeTeam.score}</p>
								<p className='fs-sm'>{eventBoxScore.homeTeam.name}</p>
								<img className='w-30' src={eventBoxScore.homeTeam.teamLogo} alt={eventBoxScore.homeTeam.name} />
							</div> */}

							<div className='home-team flex-column align-items-end'>
								<div className='d-flex align-items-center gap-2 fit-content'>
									<img className='w-30' src={eventBoxScore.homeTeam.teamLogo} alt={eventBoxScore.homeTeam.name} />
									<p className='fs-sm'>{eventBoxScore.homeTeam.name}</p>
									<p className='fs-sm fw-600'>{eventBoxScore.homeTeam.score}</p>
								</div>
								{/* {eventBoxScore.homeTeam.lastFiveGames && (
									<div className='d-flex align-items-center gap-1 fit-content ml-auto'>
										{eventBoxScore.homeTeam.lastFiveGames.events.map((event) => (
											<>
												<p
													title={`Week ${event.week} ${event.atVs} ${event?.opponent?.displayName} • ${event.score} ${event.gameResult}`}
													className={`d-flex align-items-center justify-content-center w-12 h-12 fs-tiny fw-600 bg-gradient rounded-circle text-white  ${
														event.gameResult === 'W' ? 'bg-success' : 'bg-danger'
													}`}
												>
													{event.gameResult}
												</p>
											</>
										))}
									</div>
								)} */}
							</div>
						</div>

						{statusDisplayText.includes('Final') && (
							<p className='badge bg-danger text-white bg-gradient fs-xs fw-600 lh-1'>{statusDisplayText.toUpperCase()}</p>
						)}

						{!statusDisplayText.includes('Final') && rowData.status.type.state !== 'pre' && (
							<p className='badge bg-success text-white bg-gradient fs-xs fw-600 lh-1'>{statusDisplayText}</p>
						)}

						{rowData.status.type.state === 'pre' && (
							<p
								className='d-flex align-items-center justify-content-center lh-normal flex-wrap ug-2 badge bg-warning text-white bg-gradient fs-xs fw-600 lh-1 text-uppercase'
								style={{ minWidth: '100px' }}
							>
								{formattedDate}
								<Countdown
									data={date}
									countdown
									text={false}
									// countdownClassName='fs-xs fw-600 text-body-secondary lh-normal opacity-90'
									// textClassName='fs-xxs text-body-tertiary fw-600 lh-1'
								/>
							</p>
						)}

						<div
							className='event-venue-image rounded-2'
							style={{
								backgroundImage: `url(${eventVenueImageURL})`,
								backgroundSize: '120%',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								width: '100%',
								height: '120px',
								maxWidth: '100%',
							}}
						/>
					</div>
				);
			},
		},
		{
			accessorKey: 'teams',
			header: 'Matchup',
			cell: (info) => {
				const { competitions } = info.row.original;
				const homeTeamLogoURL = competitions?.homeTeam?.logos[0]?.href;
				const awayTeamLogoURL = competitions?.awayTeam?.logos[0]?.href;

				const awayTeam = competitions?.awayTeam;
				const homeTeam = competitions?.homeTeam;

				return (
					<div className='matchup-column d-flex justify-content-around ug-2 ugy-5 user-select-none'>
						<div className='team-container away d-flex flex-column'>
							<img src={awayTeamLogoURL} className='w-50' alt={awayTeam.nickname} />
							<p
								className='team-name link d-flex align-items-baseline ug-1 fs-sm fw-600 lh-normal cursor-pointer'
								onClick={() => window.open(`${competitions?.awayTeam?.links[2].href}`)}
							>
								<span className='team-location fs-xs fw-400'>{awayTeam.location}</span>
								{awayTeam.nickname}
							</p>
							<div className='team-record d-flex align-items-baseline ugy-3 fs-xxxs'>
								<p className='team-record summary fs-xxs text-dark fw-600'>{awayTeam.record.items[0].summary}</p>

								<p className='team-record home d-flex align-items-baseline ugy-1 team-record home fs-xxxs'>
									<span className='team-record-label fs-xxxs'>HOME</span>
									{awayTeam.record.items[1].summary}
								</p>

								<p className='team-record away d-flex align-items-baseline ugy-1 team-record away fs-xxxs'>
									<span className='team-record-label fs-xxxs'>AWAY</span>
									{awayTeam.record.items[2].summary}
								</p>
							</div>
							<p className='team-standing-summary d-flex align-items-center ugy-1 fs-xxs' style={{ color: 'var(--bs-gray-500)' }}>
								{awayTeam.standingSummary}
							</p>
						</div>

						<div className='team-container home d-flex flex-column'>
							<img src={homeTeamLogoURL} className='w-50' alt={homeTeam.nickname} />
							<p
								className='team-name link d-flex align-items-baseline ug-1 fs-sm fw-600 lh-normal cursor-pointer'
								onClick={() => window.open(`${competitions?.homeTeam?.links[2].href}`)}
							>
								<span className='team-location fs-xs fw-400'>{homeTeam.location}</span>
								{homeTeam.nickname}
							</p>
							<div className='team-record d-flex align-items-center justify-content-end ugy-3 fs-xxxs'>
								<p className='team-record summary fs-xxs text-dark fw-600'>{homeTeam.record.items[0].summary}</p>

								<p className='team-record home d-flex align-items-baseline ugy-1 team-record home fs-xxxs'>
									<span className='team-record-label fs-xxxs'>HOME</span>
									{homeTeam.record.items[1].summary}
								</p>

								<p className='team-record away d-flex align-items-baseline ugy-1 team-record away fs-xxxs'>
									<span className='team-record-label fs-xxxs'>AWAY</span>
									{homeTeam.record.items[2].summary}
								</p>
							</div>
							<p className='team-standing-summary d-flex align-items-center ugy-1 fs-xxs' style={{ color: 'var(--bs-gray-500)' }}>
								{homeTeam.standingSummary}
							</p>
						</div>
					</div>
				);
			},
		},
		{
			header: 'Picks',
			accessorKey: 'picks',
			cell: (info) => {
				const sourceOrder = {
					'Dunkel Index': 1,
					Oddstrader: 2,
					CBS: 3,
					Covers: 4,
					Pickswise: 5,
				};

				const sortedPicks = [...info.row.original.picks].sort((a, b) => sourceOrder[a.source] - sourceOrder[b.source]);

				return (
					<div className='d-flex flex-column ugx-2'>
						{sortedPicks.map((pick) => (
							<div key={pick.id} className='pick d-flex align-items-center ugy-3'>
								<img
									title={pick.source}
									src={logos[pick.source]}
									className={`h-30 rounded ${pick.pickSourceURL && 'cursor-pointer'}`}
									alt={pick.source}
									onClick={() => pick.pickSourceURL && window.open(pick.pickSourceURL, '_blank')}
									style={{ opacity: '0.90' }}
								/>

								{/* DUNKEL INDEX */}
								{pick.source === 'Dunkel Index' && (
									<div className='pick-value-container dunkel-index d-flex align-items-center ug-2'>
										<div className={`pick fit-content ${pick.analysis && 'pick-of-day'}`}>
											<div className='pick-logo w-35 h-35' style={{ backgroundImage: `url(${pick.pickTeamLogo})` }} />
											<p className='pick-value fs-sm fw-600 lh-normal'>{pick.pickValue.split(' ')[0]}</p>
										</div>

										{pick.pickValue.includes('Over') ? (
											<img className='h-30' src={overUnderIconRef.over} alt='Over Icon' />
										) : (
											<img className='h-30' src={overUnderIconRef.under} alt='Under Icon' />
										)}

										<div className='d-flex align-items-center fit-content ug-2 ml-auto'>
											{pick.analysis && (
												<div
													className='d-flex align-items-center justify-content-center w-35 h35 fit-content bg-body-tertiary bg-gradient rounded p-1 cursor-pointer'
													onClick={() => {
														setSelectedPick(pick);
														setShowDescriptionModal(true);
													}}
												>
													<TbFileText
														className='text-primary'
														title={`${pick.source} | Pick Analysis`}
														// strokeWidth={2.2}
														size={24}
													/>
												</div>
											)}

											{pick.pickSourceURL && (
												<div
													className='d-flex align-items-center justify-content-center w-35 h35 fit-content bg-body-tertiary bg-gradient rounded p-1 cursor-pointer'
													onClick={() => window.open(pick.pickSourceURL, '_blank')}
												>
													<TbExternalLink
														className='text-primary'
														title={`${pick.source} | Pick Source`}
														strokeWidth={2.2}
														size={24}
													/>
												</div>
											)}
										</div>
									</div>
								)}

								{/* ODDSTRADER */}
								{pick.source === 'Oddstrader' && pick.pickValue && (
									<div className='pick-value-container oddstrader d-flex align-items-center ug-2'>
										<div className='pick fit-content'>
											<div
												className='pick-logo w-35 h-35'
												style={{
													backgroundImage: `url(${pick.pickValue.spread.icon || pick.pickValue.spread.defaultIcon})`,
													backgroundSize: '120%',
												}}
											/>
											<p className='pick-value fs-sm fw-600 lh-normal'>{pick.pickValue.spread?.value}</p>
										</div>

										<div className='d-flex align-items-center ugy-3 fit-content'>
											<div className='position-relative d-flex align-items-center justify-content-center rounded py-1'>
												<p
													className='position-absolute fw-600 fs-xxxs lh-normal'
													style={{ top: '-5px', left: '50%', transform: 'translate(-50%)', color: '#21252938' }}
												>
													TOTAL
												</p>
												<img className='h-30' src={pick.pickValue.total?.icon} alt='' />
												<p className='fw-700'>{pick.pickValue.total?.value}</p>
											</div>
											<div className='position-relative d-flex align-items-center justify-content-center rounded py-1'>
												<p
													className='position-absolute fw-600 fs-xxxs lh-normal'
													style={{ top: '-5px', left: '50%', transform: 'translate(-50%)', color: '#21252938' }}
												>
													ML
												</p>
												<img className='h-30' src={pick.pickValue.moneyline?.icon} alt='' />
												{/* <p className='fw-600'>{pick.pickValue.moneyline?.value}</p> */}
											</div>
										</div>

										<div className='d-flex align-items-center fit-content ug-2 ml-auto'>
											{pick.pickSourceURL && (
												<div
													className='d-flex align-items-center justify-content-center w-35 h35 fit-content bg-body-tertiary bg-gradient rounded p-1 cursor-pointer'
													onClick={() => window.open(pick.pickSourceURL, '_blank')}
												>
													<TbExternalLink
														className='text-primary'
														title={`${pick.source} | Pick Source`}
														strokeWidth={2.2}
														size={24}
													/>
												</div>
											)}
										</div>
									</div>
								)}

								{/* CBS */}
								{pick.source === 'CBS' && (
									<div className='pick-value-container cbs d-flex align-items-center ug-2'>
										<div className='pick fit-content'>
											<div
												className='pick-logo w-35 h-35'
												style={{ backgroundImage: `url(${pick.pickValue.consensusPickTeamLogo})` }}
											/>
											<p className='pick-value fs-sm fw-600 lh-normal'>{pick.pickValue.consensusPickDisplayText}</p>
										</div>

										{pick.pickValue && (
											<>
												<div className='d-flex align-items-center ug-1 bg-light bg-gradient px-2 py-1 rounded fit-content'>
													{pick.pickValue.picksList.map((item, index) => {
														const teamAbbreviation = item.split(' ')[0];
														const isConsensusPick = teamAbbreviation === pick.pickValue.consensusPick.split(' ')[0];
														const teamLogo =
															teamAbbreviation === pick.matchedTeamNextEvent.competitions.awayTeam.abbreviation
																? pick.matchedTeamNextEvent.competitions.awayTeam.logos[0].href
																: pick.matchedTeamNextEvent.competitions.homeTeam.logos[0].href;

														return (
															<div
																key={index}
																title={`${pick.pickValue.picksList[index]}`}
																className='w-20 h-20'
																style={{
																	backgroundImage: `url(${teamLogo})`,
																	backgroundPosition: 'center',
																	backgroundSize: 'cover',
																	backgroundRepeat: 'no-repeat',
																}}
															/>
														);
													})}
												</div>

												<div className='d-flex align-items-center fit-content ug-2 ml-auto'>
													{pick.analysis && (
														<div
															className='d-flex align-items-center justify-content-center w-35 h35 fit-content bg-body-tertiary bg-gradient rounded p-1 cursor-pointer'
															onClick={() => {
																setSelectedPick(pick);
																setShowDescriptionModal(true);
															}}
														>
															<TbFileText
																className='text-primary'
																title={`${pick.source} | Pick Analysis`}
																// strokeWidth={2.2}
																size={24}
															/>
														</div>
													)}

													{pick.pickSourceURL && (
														<div
															className='d-flex align-items-center justify-content-center w-35 h35 fit-content bg-body-tertiary bg-gradient rounded p-1 cursor-pointer'
															onClick={() => window.open(pick.pickSourceURL, '_blank')}
														>
															<TbExternalLink
																className='text-primary'
																title={`${pick.source} | Pick Source`}
																strokeWidth={2.2}
																size={24}
															/>
														</div>
													)}
												</div>
											</>
										)}
									</div>
								)}

								{/* COVERS */}
								{pick.source === 'Covers' && (
									<div className='pick-value-container covers d-flex align-items-center ug-2'>
										<div className='pick fit-content'>
											<div className='pick-logo w-25 h-25' style={{ backgroundImage: `url(${pick.pickTeamLogo})` }} />
											{/* <p className='pick-value fs-sm fw-600 lh-normal'>{pick.pickValue.displayText}</p> */}
											<p className='pick-value fs-xs fw-600 lh-normal' style={{ marginLeft: '5px' }}>
												{/* {pick.pickValue.displayText.match(/[+-]?\d+(\.\d+)?/)[0]} */}
												{pick.pickValue.displayText}
											</p>
										</div>

										<div className='d-flex align-items-center fit-content ug-2 ml-auto'>
											{pick.analysis && (
												<div
													className='d-flex align-items-center justify-content-center w-35 h35 fit-content bg-body-tertiary bg-gradient rounded p-1 cursor-pointer'
													onClick={() => {
														setSelectedPick(pick);
														setShowDescriptionModal(true);
													}}
												>
													<TbFileText
														className='text-primary'
														title={`${pick.source} | Pick Analysis`}
														// strokeWidth={2.2}
														size={24}
													/>
												</div>
											)}

											{pick.pickSourceURL && (
												<div
													className='d-flex align-items-center justify-content-center w-35 h35 fit-content bg-body-tertiary bg-gradient rounded p-1 cursor-pointer'
													onClick={() => window.open(pick.pickSourceURL, '_blank')}
												>
													<TbExternalLink
														className='text-primary'
														title={`${pick.source} | Pick Source`}
														strokeWidth={2.2}
														size={24}
													/>
												</div>
											)}
										</div>
									</div>
								)}

								{/* PICKSWISE */}
								{pick.source === 'Pickswise' && (
									<div className='pick-value-container pickswise d-flex align-items-center ug-2'>
										<div className='pick fit-content ugy-2'>
											<div className='pick-logo w-35 h-35' style={{ backgroundImage: `url(${pick.pickValue.pickIcon})` }} />
											{/* <p className='pick-value fs-sm fw-600 lh-normal'>{pick.pickValue.displayText}</p> */}
											<p className='pick-value fs-sm fw-600 lh-normal'>
												{pick.pickValue.displayText.match(/[+-]?\d+(\.\d+)?/)?.[0]}
											</p>
										</div>

										{pick.pickValue.confidence && (
											<div className='d-flex align-items-center fit-content' style={{ gap: '1px' }}>
												{pick.pickValue.confidence.map((item, index) =>
													item === '*' ? (
														<TbStarFilled key={index} color={'#E52017'} size={14} />
													) : (
														<TbStarFilled key={index} color={'#CBD5E1'} size={14} />
													)
												)}
											</div>
										)}

										<div className='d-flex align-items-center fit-content ug-2 ml-auto'>
											{pick.analysis && (
												<div
													className='d-flex align-items-center justify-content-center w-35 h35 fit-content bg-body-tertiary bg-gradient rounded p-1 cursor-pointer'
													onClick={() => {
														setSelectedPick(pick);
														setShowDescriptionModal(true);
													}}
												>
													<TbFileText
														className='text-primary'
														title={`${pick.source} | Pick Analysis`}
														// strokeWidth={2.2}
														size={24}
													/>
												</div>
											)}

											{pick.pickSourceURL && (
												<div
													className='d-flex align-items-center justify-content-center w-35 h35 fit-content bg-body-tertiary bg-gradient rounded p-1 cursor-pointer'
													onClick={() => window.open(pick.pickSourceURL, '_blank')}
												>
													<TbExternalLink
														className='text-primary'
														title={`${pick.source} | Pick Source`}
														strokeWidth={2.2}
														size={24}
													/>
												</div>
											)}
										</div>
									</div>
								)}
							</div>
						))}
					</div>
				);
			},
		},
	];

	// Initialize table
	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<>
			<div className='data-table-wrapper'>
				<div className='position-relative d-flex align-items-center flex-wrap mb-40 mb-3 ug-2'>
					<img src={logos.nfl} alt='NFL Logo' className='h-60 opacity-75' />
					<p
						className='fs-xl fw-300 text-uppercase lh-1'
						style={{
							background: 'linear-gradient(90deg, #495057, #ced4da)',
							WebkitBackgroundClip: 'text',
							WebkitTextFillColor: 'transparent',
							letterSpacing: '-3px',
							paddingRight: '10px',
							opacity: 0.8,
						}}
					>
						Events <span className='fw-200'>&</span> Picks
					</p>
					<Button
						variant='secondary'
						onClick={handleRefresh}
						disabled={isScraping || isLoading}
						className='dynamic-btn-color d-flex align-items-center ugy-2 fs-sm p-1 px-2 bg-gradient'
						style={{ borderColor: 'var(--text-light)', backgroundColor: buttonColor }}
					>
						{isScraping && <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />}
						<span className='fw-500 lh-normal text-uppercase text-light'>Scrap{isScraping ? 'ing' : 'e'}</span>
					</Button>

					{time && (
						<p
							className='position-absolute fs-xxs fw-500 text-uppercase text-body-tertiary mb-4 pt-5 opacity-50'
							style={{ bottom: 0, left: 0, bottom: '-30px', left: '70px' }}
						>
							Last fetched:{' '}
							<span className='fw-400' style={{ color: buttonColor }}>
								{moment(time).fromNow()}
							</span>
						</p>
					)}
				</div>

				<table className='table data-table table-striped nfl'>
					<thead className='data-table-header'>
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<th
										className={`data-table-header-cell ${header.column.id} bg-dark bg-gradient text-light text-uppercase`}
										key={header.id}
									>
										{flexRender(header.column.columnDef.header, header.getContext())}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody className='data-table-body'>
						{data.length > 0 ? (
							table.getRowModel().rows.map((row) => (
								<tr key={row.id} className='data-table-row'>
									{row.getVisibleCells().map((cell) => (
										<td key={cell.id} className={`data-table-cell ${cell.column.id}`}>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</td>
									))}
								</tr>
							))
						) : (
							<tr>
								<td colSpan={table.getAllColumns().length} className='text-center py-4'>
									<p className='text-body-tertiary'>
										No <span className=''>{statusLabels[filters.status]}</span> Events Found.
									</p>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>

			{showDescriptionModal && selectedPick && (
				<Modal centered size='lg' show={showDescriptionModal} onHide={() => setShowDescriptionModal(false)}>
					<Modal.Header closeButton className='border-0'>
						<Modal.Title>
							<div className='d-flex align-items-center ug-2'>
								<div className='d-flex align-items-center ug-2'>
									<img
										src={selectedPick.matchedTeamNextEvent.competitions.awayTeam.logos[0].href}
										className='h-30'
										alt={selectedPick.matchedTeamNextEvent.competitions.awayTeam.nickname}
									/>
									{selectedPick.matchedTeamNextEvent.competitions.awayTeam.nickname}
								</div>
								<span className='fs-sm text-muted fw-600'>@</span>
								<div className='d-flex align-items-center ug-2'>
									<img
										src={selectedPick.matchedTeamNextEvent.competitions.homeTeam.logos[0].href}
										className='h-30'
										alt={selectedPick.matchedTeamNextEvent.competitions.homeTeam.nickname}
									/>
									{selectedPick.matchedTeamNextEvent.competitions.homeTeam.nickname}
								</div>
							</div>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div key={selectedPick.id} className='pick d-flex align-items-center ugy-3 bg-light p-2 rounded fit-content mb-10'>
							<img
								title={selectedPick.source}
								src={logos[selectedPick.source]}
								className={`h-30 rounded ${selectedPick.pickSourceURL && 'cursor-pointer'}`}
								alt={selectedPick.source}
								onClick={() => selectedPick.pickSourceURL && window.open(selectedPick.pickSourceURL, '_blank')}
								style={{ opacity: '0.90' }}
							/>
							{/* DUNKEL INDEX */}
							{selectedPick.source === 'Dunkel Index' && (
								<div className='pick-value dunkel-index d-flex align-items-center ug-2'>
									<img src={selectedPick.pickTeamLogo} className='h-30' alt={selectedPick.source} />
									<p className='fs-sm fw-600 lh-normal'>{selectedPick.pickValue.split(' ')[0]}</p>
									{selectedPick.pickValue.includes('Over') ? (
										<img className='h-30' src={overUnderIconRef.over} alt='Over Icon' />
									) : (
										<img className='h-30' src={overUnderIconRef.under} alt='Under Icon' />
									)}
								</div>
							)}
							{/* COVERS */}
							{selectedPick.source === 'Covers' && (
								<div className='pick-value covers d-flex align-items-center ug-2'>
									<img src={selectedPick.pickTeamLogo} className='h-30' alt={selectedPick.source} />
									<p className='fs-sm fw-600 lh-normal'>{selectedPick.pickValue.displayText}</p>
								</div>
							)}
							{/* PICKSWISE */}
							{selectedPick.source === 'Pickswise' && (
								<div className='pick-value pickswise d-flex align-items-center ug-2'>
									{selectedPick.pickValue.pickIcon && (
										<>
											<img src={selectedPick.pickValue.pickIcon} className='h-35' alt={selectedPick.source} />
											<p className='fs-sm fw-600 lh-normal'>{selectedPick.pickValue.displayText}</p>
										</>
									)}
									{selectedPick.pickValue.confidence && (
										<div className='d-flex align-items-center' style={{ gap: '1px' }}>
											{selectedPick.pickValue.confidence.map((item, index) =>
												item === '*' ? (
													<TbStarFilled key={index} color={'#E52017'} size={14} />
												) : (
													<TbStarFilled key={index} color={'#CBD5E1'} size={14} />
												)
											)}
										</div>
									)}
								</div>
							)}
						</div>
						{selectedPick.analysis ? selectedPick.analysis : 'No analysis available.'}
					</Modal.Body>
					<Modal.Footer className='border-0'>
						<Button variant='secondary' onClick={() => setShowDescriptionModal(false)}>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</>
	);
};

export default NFLDataTable;
