// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import moment from 'moment';
// ASSETS
import { TbStar, TbStarFilled, TbFileText, TbExternalLink, TbRefresh } from 'react-icons/tb';
import dunkelIndexLogo from '../../assets/img/dunkel-index.svg';
import oddstraderLogo from '../../assets/img/oddstrader-alt.svg';
import coversLogo from '../../assets/img/covers.svg';
import pickswiseLogo from '../../assets/img/pickswise.svg';
import cbsSportsLogo from '../../assets/img/cbs-sports.svg';
import eventVenuePlaceholder from '../../assets/img/event-venue-placeholder--light.svg';
// COMPONENTS
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { Button, Spinner, Modal } from 'react-bootstrap';
import Countdown from '../Countdown';
// SERVICES & HELPERS
import db from '../../stores/db';
// import { getCFBData, fetchBoxScoreByEventId } from '../../services/cfbService';
import { getCFBData, fetchBoxScoreByEventId } from '../../services/cfbService';
import {
	getEventsByLeague,
	getPicksForEvent,
	getFavoriteIds,
	getLastFetchTime,
	setLastFetchTime,
	updateEventsByLeague,
} from '../../stores/dbQueries';

const overUnderIconRef = {
	over: 'https://otcdn.virginia.us-east-1.oddstrader.com/bettingOptionIcons/over-icon.svg',
	under: 'https://otcdn.virginia.us-east-1.oddstrader.com/bettingOptionIcons/under-icon.svg',
	push: 'https://otcdn.virginia.us-east-1.oddstrader.com/bettingOptionIcons/push-icon.svg',
};

const logos = {
	Oddstrader: oddstraderLogo,
	Covers: coversLogo,
	Pickswise: pickswiseLogo,
	'Dunkel Index': dunkelIndexLogo,
	CBS: cbsSportsLogo,
	cfb: 'https://static.www.cfb.com/image/upload/v1554321393/league/nvfr7ogywskqrfaiu38m.svg',
	cfb: 'https://upload.wikimedia.org/wikipedia/commons/d/dd/NCAA_logo.svg',
};

const statusPriorityOrder = {
	2: 1,
	1: 2,
	3: 3,
};

const statusLabels = {
	in: 'In Progress',
	pre: 'Scheduled',
	post: 'Completed',
	cancelled: 'Cancelled',
	final: 'Final',
};

const calculateButtonColor = (lastFetchTime) => {
	if (!lastFetchTime) return 'hsl(0, 70%, 50%)';
	const minutesSinceFetch = moment().diff(moment(lastFetchTime), 'minutes');
	const minMinutes = 0;
	const maxMinutes = 60;

	const clampedMinutes = Math.min(Math.max(minutesSinceFetch, minMinutes), maxMinutes);

	const hue = (clampedMinutes * 120) / maxMinutes;

	return `hsl(${hue}, 70%, 50%)`;
};

const CFBDataTable = (props) => {
	const { filters } = props;
	const [data, setData] = useState([]);
	const [favorites, setFavorites] = useState([]);
	const [lastScrapedTime, setLastScrapedTime] = useState(null);
	const [lastUpdatedTime, setLastUpdatedTime] = useState(null);
	const [buttonColor, setButtonColor] = useState('hsl(120, 70%, 50%)');
	// LOADING STATES
	const [isLoading, setIsLoading] = useState(false);
	const [isScraping, setIsScraping] = useState(false);
	const [isUpdating, setIsUpdating] = useState(false);
	// PICK DESCRIPTION MODAL
	const [selectedPick, setSelectedPick] = useState(null);
	const [showDescriptionModal, setShowDescriptionModal] = useState(false);

	useEffect(() => {
		fetchData();
	}, [filters.status]);

	useEffect(() => {
		const updateButtonColor = () => setButtonColor(calculateButtonColor(lastScrapedTime));
		updateButtonColor();

		const interval = setInterval(updateButtonColor, 60000);
		return () => clearInterval(interval);
	}, [lastScrapedTime]);

	useEffect(() => {
		if (selectedPick) {
			console.log('SELECTED PICK \n', selectedPick);
		}
	}, [selectedPick]);

	const fetchData = async () => {
		setIsLoading(true);

		const storedLastFetch = await getLastFetchTime('cfb');
		setLastScrapedTime(storedLastFetch);
		setButtonColor(calculateButtonColor(storedLastFetch));

		const storedLastUpdated = await db.metadata
			.where('key')
			.equals('lastFetchedCFBEvents')
			.and((meta) => meta.league === 'cfb')
			.first()
			.then((metadata) => metadata?.value || null);
		setLastUpdatedTime(storedLastUpdated);

		// const storedEvents = await getEventsByLeague('cfb');
		const storedEvents = await getEventsByLeague('cfb', filters.status);

		if (!storedEvents || !storedEvents.length > 0) {
			console.warn('CFB EVENTS DATA EMPTY');
			setIsLoading(false);
			return;
		}

		const eventsWithPicksAndBoxScores = await Promise.all(
			storedEvents.map(async (event) => {
				const picks = await getPicksForEvent(event.id);
				const boxscoreData = await fetchBoxScoreByEventId(event.id);

				return {
					...event,
					picks,
					boxscore: boxscoreData,
				};
			})
		);

		const favoriteIds = await getFavoriteIds();
		const sortedData = sortEventsByStatusAndFavorites(eventsWithPicksAndBoxScores, favoriteIds);

		console.log(
			'%cCFB | DATA TABLE',
			'background: #2F3337; color: white; font-weight: bold; border-radius: 5px; padding: 2px 6px; font-size: 11px;',
			// '\n',
			sortedData
		);

		setData(sortedData);
		setFavorites(favoriteIds);
		setIsLoading(false);
	};

	const getFavoriteIds = async () => {
		const favorites = await db.favorites.toArray();
		return favorites.map((fav) => fav.eventId);
	};

	const toggleFavoriteEvent = async (eventId) => {
		const existingFavorite = await db.favorites.where('eventId').equals(eventId).first();
		if (existingFavorite) {
			await db.favorites.delete(existingFavorite.id);
		} else {
			await db.favorites.add({ eventId });
		}
	};

	const sortEventsByFavorites = (events, favoriteIds) => {
		return events.sort((a, b) => {
			const aIsFavorite = favoriteIds.includes(a.id);
			const bIsFavorite = favoriteIds.includes(b.id);
			return aIsFavorite === bIsFavorite ? 0 : aIsFavorite ? -1 : 1;
		});
	};

	const handleFavoriteToggle = async (eventId) => {
		await toggleFavoriteEvent(eventId);
		const updatedFavorites = await getFavoriteIds();
		const sortedData = sortEventsByFavorites(data, updatedFavorites);
		setData(sortedData);
		setFavorites(updatedFavorites);

		fetchData();
	};

	const handleRefresh = async () => {
		setIsScraping(true);
		await getCFBData();
		await fetchData();
		setIsScraping(false);
	};

	const sortEventsByStatusAndFavorites = (events, favoriteIds) => {
		return events.sort((a, b) => {
			const aIsFavorite = favoriteIds.includes(a.id);
			const bIsFavorite = favoriteIds.includes(b.id);

			// Prioritize favorites
			if (aIsFavorite !== bIsFavorite) {
				return aIsFavorite ? -1 : 1;
			}

			// If both are favorites or neither, sort by status priority
			const aStatusPriority = statusPriorityOrder[a.status.type.id] || 99;
			const bStatusPriority = statusPriorityOrder[b.status.type.id] || 99;

			return aStatusPriority - bStatusPriority;
		});
	};

	const handleRefreshStatuses = async () => {
		setIsUpdating(true);
		try {
			await updateEventsByLeague('cfb', 'football', 'college-football');
			fetchData();
		} catch (error) {
			console.error('ERROR REFRESHING CFB EVENT\n', error);
		} finally {
			setIsUpdating(false);
		}
	};

	const columns = [
		{
			accessorKey: 'favorite',
			header: '★',
			cell: (info) => {
				const eventId = info.row.original.id;
				const index = info.row.index;

				return (
					<>
						<span className='index'>{index}</span>
						<Button variant='link' onClick={() => handleFavoriteToggle(eventId)} className='user-select-none'>
							{favorites.includes(eventId) ? (
								<TbStarFilled color={'#FFD101'} strokeWidth={1.6} size={28} />
							) : (
								<TbStar color={'#FFD101'} strokeWidth={1.6} size={28} />
							)}
						</Button>
					</>
				);
			},
		},
		{
			accessorKey: 'date',
			header: 'Details',
			size: 500,
			minSize: 50,
			maxSize: 500,
			cell: (info) => {
				const rowData = info.row.original;
				const date = info.getValue();
				const eventId = info.row.original.id;

				// EVENT HEADER DATA
				const header = {
					league: rowData.league.toUpperCase(),
					week: `WEEK ${rowData.week}`,
					seasonType: rowData.seasonType.toUpperCase(),
					matchup: `${rowData.competitions.awayTeam.location.toUpperCase()} @ ${rowData.competitions.homeTeam.location.toUpperCase()}`,
				};

				const boxScoreHeaderCompetitions = rowData.boxscore.header.competitions[0];
				const eventBoxScore = {
					awayTeam: {
						linescores: boxScoreHeaderCompetitions?.competitors?.[1] || null,
						score: boxScoreHeaderCompetitions?.competitors?.[1]?.score || 0,
						scoreDifference:
							boxScoreHeaderCompetitions.status.type.state !== 'pre' &&
							boxScoreHeaderCompetitions?.competitors?.[1]?.score - boxScoreHeaderCompetitions?.competitors?.[0]?.score,
						name: boxScoreHeaderCompetitions?.competitors?.[1]?.team?.nickname || 'N/A',
						teamLogo: boxScoreHeaderCompetitions?.competitors?.[1]?.id
							? `https://a.espncdn.com/i/teamlogos/ncaa/500/${boxScoreHeaderCompetitions.competitors[1].id}.png`
							: boxScoreHeaderCompetitions?.competitors?.[1]?.team?.logos?.[1]?.href ||
							  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWdoozTKQWsJeD1CyzzJGe-cA1LUXUAFUrWQ&s',
					},
					homeTeam: {
						linescores: boxScoreHeaderCompetitions?.competitors?.[0] || null,
						score: boxScoreHeaderCompetitions?.competitors?.[0]?.score || 0,
						scoreDifference:
							boxScoreHeaderCompetitions.status.type.state !== 'pre' &&
							boxScoreHeaderCompetitions?.competitors?.[1]?.score - boxScoreHeaderCompetitions?.competitors?.[0]?.score,
						name: boxScoreHeaderCompetitions?.competitors?.[0]?.team?.nickname || 'N/A',
						teamLogo: boxScoreHeaderCompetitions?.competitors?.[0]?.id
							? `https://a.espncdn.com/i/teamlogos/ncaa/500/${boxScoreHeaderCompetitions.competitors[0].id}.png`
							: boxScoreHeaderCompetitions?.competitors?.[0]?.team?.logos?.[0]?.href ||
							  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWdoozTKQWsJeD1CyzzJGe-cA1LUXUAFUrWQ&s',
					},
					boxscoreData: { ...boxScoreHeaderCompetitions },
				};

				const statusDisplayText = rowData.statusDisplayText;
				const formattedDate = moment(date).format('ddd MM/D');
				const eventVenue = rowData?.boxscore?.gameInfo?.venue;
				const eventVenueImageURL = eventVenue?.images[1]?.href || eventVenue?.images[0]?.href;

				return (
					<>
						<span className='index eventid'>{eventId}</span>
						<div className='details d-flex flex-column ug-1'>
							{/* DETAILS - HEADER */}
							<p className='details-header position-relative fs-xxs text-body-tertiary fw-600 lh-1 text-center'>
								<span>{header.league}</span>
								<span className='fs-sm text-gray-400 user-select-none'> • </span>
								<span>{header.week}</span>
								<span className='fs-sm text-gray-400 user-select-none'> • </span>
								<span>{header.seasonType}</span>
								<span className='fs-sm text-gray-400 user-select-none'> • </span>
								<span>{header.matchup}</span>
							</p>

							{/* DETAILS - BOXSCORE */}
							<div className='details-boxscore event-boxscore d-flex justify-content-between'>
								{/* AWAY TEAM */}
								<div className='away-team d-flex align-items-center gap-2 fit-content'>
									<img className='w-30' src={eventBoxScore.awayTeam.teamLogo} alt={eventBoxScore.awayTeam.name} />
									<span className='team-location position-relative fs-sm text-dark fw-600'>
										{eventBoxScore.awayTeam.name}
										{rowData.competitions.awayTeam?.rank <= 25 && (
											<span className='away-team-rank position-absolute fs-xxxs lh-normal text-body-tertiary'>
												{rowData.competitions.awayTeam.rank}
											</span>
										)}
									</span>
									<p className='fs-mdsm fw-600'>{eventBoxScore.awayTeam.score}</p>
								</div>
								{/* HOME TEAM */}
								<div className='home-team d-flex align-items-center gap-2 fit-content'>
									<p className='fs-md fw-600'>{eventBoxScore.homeTeam.score}</p>
									<span className='team-location position-relative fs-sm text-dark fw-600'>
										{eventBoxScore.homeTeam.name}
										{rowData.competitions.homeTeam?.rank <= 25 && (
											<span className='home-team-rank position-absolute fs-xxxs lh-normal text-body-tertiary'>
												{rowData.competitions.homeTeam.rank}
											</span>
										)}
									</span>

									<img className='w-30' src={eventBoxScore.homeTeam.teamLogo} alt={eventBoxScore.homeTeam.name} />
								</div>
							</div>

							{/* DETAILS - SCORE */}
							<>
								{statusDisplayText.includes('Final') && (
									<p className='badge bg-danger text-white bg-gradient fs-xs fw-600 lh-1'>{statusDisplayText.toUpperCase()}</p>
								)}

								{!statusDisplayText.includes('Final') && rowData.status.type.state !== 'pre' && (
									<p className='badge bg-success text-white bg-gradient fs-xs fw-600 lh-1'>{statusDisplayText}</p>
								)}

								{rowData.status.type.state === 'pre' && (
									<p
										className='d-flex align-items-center justify-content-center lh-normal flex-wrap ug-2 badge bg-warning text-white bg-gradient fs-xs fw-600 text-uppercase p-0 py-1'
										style={{ minWidth: '100px' }}
									>
										{formattedDate}
										<span className=''>{moment(date).format('h:mm A')}</span>
										<Countdown data={date} countdown text={false} countdownClassName='Xtext-lowercase' />
									</p>
								)}
							</>

							{/* DETAILS - VENUE */}
							<>
								<div
									className='event-venue-image rounded-2'
									style={{
										backgroundImage: `url(${eventVenueImageURL || eventVenuePlaceholder})`,
										backgroundSize: 'cover',
										backgroundPosition: 'center center',
										backgroundRepeat: 'no-repeat',
										width: '100%',
										height: '130px',
										maxWidth: '100%',
									}}
								/>
							</>
						</div>
					</>
				);
			},
		},
		{
			accessorKey: 'teams',
			header: 'Matchup',
			cell: (info) => {
				const competitions = info.row.original.competitions || {};
				const homeTeam = competitions.homeTeam || {};
				const awayTeam = competitions.awayTeam || {};

				const homeTeamLogoURL = homeTeam.logos?.[0]?.href || '';
				const awayTeamLogoURL = awayTeam.logos?.[0]?.href || '';

				// SAFELY ACCESS TEAM RECORD DATA
				const awaySummary = awayTeam.record?.items?.[0]?.summary;
				const awayHomeRecord = awayTeam.record?.items?.[1]?.summary;
				const awayAwayRecord = awayTeam.record?.items?.[2]?.summary;
				const homeSummary = homeTeam.record?.items?.[0]?.summary;
				const homeHomeRecord = homeTeam.record?.items?.[1]?.summary;
				const homeAwayRecord = homeTeam.record?.items?.[2]?.summary;

				return (
					<div className='matchup-column d-flex justify-content-between ug-2 ugy-5'>
						{/* AWAY TEAM */}
						<div className='team-container away d-flex flex-column'>
							{awayTeamLogoURL && (
								<img
									src={`https://a.espncdn.com/i/teamlogos/ncaa/500/${awayTeam.id}.png`}
									className='w-50'
									alt={awayTeam.nickname || 'Away Team'}
								/>
							)}
							<p
								className='team-name link d-flex align-items-baseline flex-wrap ug-1 fs-sm fw-600 lh-normal cursor-pointer'
								onClick={() => awayTeam.links?.[2]?.href && window.open(awayTeam.links[2].href)}
							>
								<span className='team-location position-relative fs-sm text-dark fw-600'>
									{awayTeam.shortDisplayName}
									{awayTeam?.rank <= 25 && (
										<span className='position-absolute fs-xxs lh-normal text-body-tertiary' style={{ top: '-0.4rem' }}>
											{awayTeam.rank}
										</span>
									)}
								</span>
							</p>
							<div className='team-record d-flex align-items-baseline ugy-2 fs-xxxs'>
								<p className='team-record summary fs-xxs text-dark fw-600'>{awaySummary}</p>
								{awayHomeRecord && (
									<p className='team-record home fs-xxxs'>
										<span className='team-record-label fs-xxxs px-1'>HOME</span>
										<span className='fs-xxs fw-400'>{awayHomeRecord}</span>
									</p>
								)}
								{awayAwayRecord && (
									<p className='team-record away fs-xxxs'>
										<span className='team-record-label fs-xxxs px-1'>AWAY</span>
										<span className='fs-xxs fw-400'>{awayAwayRecord}</span>
									</p>
								)}
							</div>
							<p className='team-standing-summary fs-xxs' style={{ color: 'var(--bs-gray-500)' }}>
								{awayTeam.standingSummary || 'No Standing Summary'}
							</p>
						</div>

						{/* HOME TEAM */}
						<div className='team-container home d-flex flex-column'>
							{homeTeamLogoURL && (
								<img
									src={`https://a.espncdn.com/i/teamlogos/ncaa/500/${homeTeam.id}.png`}
									className='w-50'
									alt={homeTeam.nickname || 'Home Team'}
								/>
							)}
							<p
								className='team-name link d-flex align-items-baseline flex-wrap ug-1 fs-sm fw-600 lh-normal cursor-pointer'
								onClick={() => homeTeam.links?.[2]?.href && window.open(homeTeam.links[2].href)}
							>
								<span className='team-location position-relative fs-sm text-dark fw-600'>
									{homeTeam.shortDisplayName}
									{homeTeam?.rank <= 25 && (
										<span
											className='position-absolute left-0 fs-xxs lh-normal text-body-tertiary'
											style={{ top: '-0.4rem', left: '-1rem' }}
										>
											{homeTeam.rank}
										</span>
									)}
								</span>
							</p>
							<div className='team-record d-flex align-items-center justify-content-end ugy-2 fs-xxxs'>
								{homeAwayRecord && (
									<p className='team-record away fs-xxxs'>
										<span className='team-record-label fs-xxxs px-1'>AWAY</span>
										<span className='fs-xxs fw-400'>{homeAwayRecord}</span>
									</p>
								)}
								{homeHomeRecord && (
									<p className='team-record home fs-xxxs'>
										<span className='team-record-label fs-xxxs px-1'>HOME</span>
										<span className='fs-xxs fw-400'>{homeHomeRecord}</span>
									</p>
								)}
								<p className='team-record summary fs-xxs text-dark fw-600'>{homeSummary}</p>
							</div>
							<p className='team-standing-summary fs-xxs' style={{ color: 'var(--bs-gray-500)' }}>
								{homeTeam.standingSummary || 'No Standing Summary'}
							</p>
						</div>
					</div>
				);
			},
		},
		{
			header: 'Picks',
			accessorKey: 'picks',
			cell: (info) => {
				const sourceOrder = {
					'Dunkel Index': 1,
					Oddstrader: 2,
					CBS: 3,
					Covers: 4,
					Pickswise: 5,
				};

				const sortedPicks = [...info.row.original.picks].sort((a, b) => sourceOrder[a.source] - sourceOrder[b.source]);

				return (
					<div className='d-flex flex-column ugx-2'>
						{sortedPicks.map((pick) => (
							<div key={pick.id} className='pick d-flex align-items-center ugy-3'>
								<img
									title={pick.source}
									src={logos[pick.source]}
									className={`h-30 rounded ${pick.pickSourceURL && 'cursor-pointer'}`}
									alt={pick.source}
									onClick={() => pick.pickSourceURL && window.open(pick.pickSourceURL, '_blank')}
									style={{ opacity: '0.90' }}
								/>

								{/* DUNKEL INDEX */}
								{pick.source === 'Dunkel Index' && (
									<div className='pick-value-container dunkel-index d-flex align-items-center ug-2'>
										<div className={`pick fit-content ${pick.analysis && 'pick-of-day'}`}>
											<div className='pick-logo w-35 h-35' style={{ backgroundImage: `url(${pick.pickValue.imageURL})` }} />
											<p className='pick-value fs-sm fw-600 lh-normal'>{pick.pickValue.pick}</p>
										</div>
										<div className='d-flex align-items-center fit-content'>
											<img
												className='h-30'
												src={pick.pickValue.overUnderIcon}
												alt={`Dunkel Index Over/Under - ${pick.pickValue.overUnder} ${pick.pickValue.total}`}
											/>
											<p className='pick-value fs-sm fw-600 lh-normal'>{pick.pickValue.total}</p>
										</div>

										<div className='d-flex align-items-center fit-content ug-2 ml-auto'>
											{pick.analysis && (
												<div
													className='d-flex align-items-center justify-content-center w-35 h35 fit-content bg-body-tertiary bg-gradient rounded p-1 cursor-pointer'
													onClick={() => {
														console.log('SELECTED PICK', pick);
														setSelectedPick(pick);
														setShowDescriptionModal(true);
													}}
												>
													<TbFileText
														className='text-primary'
														title={`${pick.source} | Pick Analysis`}
														// strokeWidth={2.2}
														size={24}
													/>
												</div>
											)}

											{pick.pickSourceURL && (
												<div
													className='d-flex align-items-center justify-content-center w-35 h35 fit-content bg-body-tertiary bg-gradient rounded p-1 cursor-pointer'
													onClick={() => window.open(pick.pickSourceURL, '_blank')}
												>
													<TbExternalLink
														className='text-primary'
														title={`${pick.source} | Pick Source`}
														strokeWidth={2.2}
														size={24}
													/>
												</div>
											)}
										</div>
									</div>
								)}

								{/* ODDSTRADER */}
								{pick.source === 'Oddstrader' && pick.pickValue && (
									<div className='pick-value-container oddstrader d-flex align-items-center ug-2'>
										<div className='pick fit-content'>
											{pick.pickValue.spread?.value === '-' && (
												<>
													<div className='pick-logo w-35 h-35' style={{ backgroundImage: `url(${overUnderIconRef.push})` }} />
													<p className='pick-value fw-700'>{pick.pickValue.spread.value}</p>
												</>
											)}

											{pick.pickValue.spread?.value !== '-' && (
												<>
													<div
														className='pick-logo w-35 h-35'
														style={{
															backgroundImage: `url(${pick.pickValue.spread.icon || pick.pickValue.spread.defaultIcon})`,
															backgroundSize: '120%',
														}}
													/>
													<p className='pick-value fw-700'>{pick.pickValue.spread.value}</p>
												</>
											)}
										</div>

										{/* TOTAL */}
										{pick.pickValue.total && (
											<div className='position-relative d-flex align-items-center justify-content-center rounded py-1 fit-content'>
												<p
													className='position-absolute fw-600 fs-xxxs lh-normal'
													style={{ top: '-5px', left: '50%', transform: 'translate(-50%)', color: '#21252938' }}
												>
													TOTAL
												</p>
												{pick.pickValue.total.icon && <img className='h-30' src={pick.pickValue.total.icon} alt='' />}
												{pick.pickValue.total.value && (
													<p className='pick-value fs-sm fw-600 lh-normal'>{pick.pickValue.total.value}</p>
												)}
											</div>
										)}

										{/* MONEYLINE */}
										{pick.pickValue.moneyline && (
											<div className='position-relative d-flex align-items-center justify-content-center rounded py-1 fit-content'>
												<p
													className='position-absolute fw-600 fs-xxxs lh-normal'
													style={{ top: '-5px', left: '50%', transform: 'translate(-50%)', color: '#21252938' }}
												>
													ML
												</p>
												{pick.pickValue.moneyline.icon && (
													<img
														className='h-30'
														src={pick.pickValue.moneyline.defaultIcon || pick.pickValue.moneyline.icon}
														alt=''
													/>
												)}
											</div>
										)}

										{/* EXTERNAL LINK TO PICK SOURCE */}
										{pick.pickSourceURL && (
											<div
												className='d-flex align-items-center justify-content-center w-35 h35 fit-content bg-body-tertiary bg-gradient rounded ml-auto p-1 cursor-pointer'
												onClick={() => window.open(pick.pickSourceURL, '_blank')}
											>
												<TbExternalLink
													className='text-primary'
													title={`${pick.source} | Pick Source`}
													strokeWidth={2.2}
													size={24}
												/>
											</div>
										)}
									</div>
								)}
							</div>
						))}
					</div>
				);
			},
		},
	];

	// Initialize table
	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<>
			<div className='data-table-wrapper'>
				<div className='data-table-header position-relative d-flex align-items-center flex-wrap ug-2 user-select-none'>
					<img src={logos.cfb} alt='CFB Logo' className='h-60 opacity-75' />
					<p
						className='fs-xl fw-300 text-uppercase lh-1'
						style={{
							background: 'linear-gradient(90deg, #495057, #ced4da)',
							WebkitBackgroundClip: 'text',
							WebkitTextFillColor: 'transparent',
							letterSpacing: '-3px',
							paddingRight: '10px',
							opacity: 0.8,
						}}
					>
						Events <span className='fw-200'>&</span> Picks
					</p>
				</div>

				{/* ACTIONS */}
				<div className='d-flex align-items-start flex-wrap ug-4 my-30'>
					{/* ACTION | LAST SCRAPED */}
					<div className='d-flex flex-column'>
						<Button
							variant='secondary'
							onClick={handleRefresh}
							disabled={isScraping || isLoading || isUpdating}
							className='dynamic-btn-color d-flex align-items-center ugy-2 fs-sm p-1 px-2 bg-gradient fit-content'
							style={{ borderColor: 'var(--text-light)', backgroundColor: buttonColor }}
						>
							{isScraping && <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />}
							<span className='fw-500 lh-normal text-uppercase text-light'>Scrap{isScraping ? 'ing' : 'e'}</span>
						</Button>

						{lastScrapedTime && (
							<p
								className='fs-xxxs fw-500 text-uppercase text-body-tertiary opacity-50 lh-normal mt-1'
								style={{ bottom: 0, left: 0, bottom: '-30px', left: '70px' }}
							>
								{/* Last fetched: */}
								{/* <br /> */}
								<span className='fs-xxs fw-400' style={{ color: buttonColor }}>
									{moment(lastScrapedTime).fromNow()}
								</span>
							</p>
						)}
					</div>

					{/* ACTION | REFRESH */}
					<div className='d-flex flex-column'>
						<Button
							variant='secondary'
							title='Refresh Event Statuses'
							onClick={handleRefreshStatuses}
							disabled={isUpdating}
							className='d-flex align-items-center ugy-2 fs-sm p-1 px-2 bg-gradient fit-content'
							style={{ borderColor: 'var(--text-light)' }}
						>
							<p className='d-flex align-items-center ugy-1 fw-500 lh-normal text-uppercase text-light'>
								{isUpdating ? (
									<Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
								) : (
									<TbRefresh strokeWidth={2.5} size={16} />
									// <TbScoreboard xstrokeWidth={2} xsize={16} />
								)}
								{/* {isUpdating && <span className='mx-2'>Updating</span>} */}
								Events
							</p>
						</Button>

						{lastUpdatedTime && (
							<p
								className='fs-xxxs fw-500 text-uppercase text-body-tertiary opacity-50 lh-normal mt-1'
								style={{ bottom: 0, left: 0, bottom: '-30px', left: '70px' }}
							>
								<span className='fs-xxs fw-400 text-muted'>{moment(lastUpdatedTime).fromNow()}</span>
							</p>
						)}
					</div>
				</div>

				<table className='table data-table table-striped cfb'>
					<thead className='data-table-header'>
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<th
										className={`data-table-header-cell ${header.column.id} bg-dark bg-gradient text-light text-uppercase`}
										key={header.id}
									>
										{flexRender(header.column.columnDef.header, header.getContext())}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody className='data-table-body'>
						{data.length > 0 ? (
							table.getRowModel().rows.map((row) => (
								<tr key={row.id} className='data-table-row'>
									{row.getVisibleCells().map((cell) => (
										<td key={cell.id} className={`data-table-cell ${cell.column.id}`}>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</td>
									))}
								</tr>
							))
						) : (
							<tr>
								<td colSpan={table.getAllColumns().length} className='text-center py-4'>
									<p className='text-body-tertiary'>
										No <span className=''>{statusLabels[filters.status]}</span> Events Found.
									</p>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>

			{showDescriptionModal && selectedPick && (
				<Modal centered size='lg' show={showDescriptionModal} onHide={() => setShowDescriptionModal(false)}>
					<Modal.Header closeButton className='border-0'>
						<Modal.Title>
							<div className='d-flex align-items-center ug-2'>
								<div className='d-flex align-items-center ug-2'>
									<img
										src={selectedPick.matchedTeamNextEvent.competitions.awayTeam.logos[0].href}
										className='h-30'
										alt={selectedPick.matchedTeamNextEvent.competitions.awayTeam.nickname}
									/>
									{selectedPick.matchedTeamNextEvent.competitions.awayTeam.nickname}
								</div>
								<span className='fs-sm text-muted fw-600'>@</span>
								<div className='d-flex align-items-center ug-2'>
									<img
										src={selectedPick.matchedTeamNextEvent.competitions.homeTeam.logos[0].href}
										className='h-30'
										alt={selectedPick.matchedTeamNextEvent.competitions.homeTeam.nickname}
									/>
									{selectedPick.matchedTeamNextEvent.competitions.homeTeam.nickname}
								</div>
							</div>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div key={selectedPick.id} className='pick d-flex align-items-center ugy-3 bg-light p-2 rounded fit-content mb-10'>
							<img
								title={selectedPick.source}
								src={logos[selectedPick.source]}
								className={`h-30 rounded ${selectedPick.pickSourceURL && 'cursor-pointer'}`}
								alt={selectedPick.source}
								onClick={() => selectedPick.pickSourceURL && window.open(selectedPick.pickSourceURL, '_blank')}
								style={{ opacity: '0.90' }}
							/>
							{/* DUNKEL INDEX */}
							{selectedPick.source === 'Dunkel Index' && (
								<div className='pick-value dunkel-index d-flex align-items-center ug-2'>
									<div className='pick-value-container dunkel-index d-flex align-items-center ug-2'>
										<div className={`pick fit-content ${selectedPick.analysis && 'pick-of-day'}`}>
											<div
												className='pick-logo w-35 h-35'
												style={{ backgroundImage: `url(${selectedPick.pickValue.imageURL})` }}
											/>
											<p className='pick-value fs-sm fw-600 lh-normal'>{selectedPick.pickValue.pick}</p>
										</div>
										<div className='d-flex align-items-center fit-content'>
											<img
												className='h-30'
												src={selectedPick.pickValue.overUnderIcon}
												alt={`Dunkel Index Over/Under - ${selectedPick.pickValue.overUnder} ${selectedPick.pickValue.total}`}
											/>
											<p className='pick-value fs-sm fw-600 lh-normal'>{selectedPick.pickValue.total}</p>
										</div>

										<div className='d-flex align-items-center fit-content ug-2 ml-auto'>
											{selectedPick.pickSourceURL && (
												<div
													className='d-flex align-items-center justify-content-center w-35 h35 fit-content bg-body-tertiary bg-gradient rounded p-1 cursor-pointer'
													onClick={() => window.open(selectedPick.pickSourceURL, '_blank')}
												>
													<TbExternalLink
														className='text-primary'
														title={`${selectedPick.source} | Pick Source`}
														strokeWidth={2.2}
														size={24}
													/>
												</div>
											)}
										</div>
									</div>
								</div>
							)}
							{/* COVERS */}
							{selectedPick.source === 'Covers' && (
								<div className='pick-value covers d-flex align-items-center ug-2'>
									<img src={selectedPick.pickTeamLogo} className='h-30' alt={selectedPick.source} />
									<p className='fs-sm fw-600 lh-normal'>{selectedPick.pickValue.displayText}</p>
								</div>
							)}
							{/* PICKSWISE */}
							{selectedPick.source === 'Pickswise' && (
								<div className='pick-value pickswise d-flex align-items-center ug-2'>
									{selectedPick.pickValue.pickIcon && (
										<>
											<img src={selectedPick.pickValue.pickIcon} className='h-35' alt={selectedPick.source} />
											<p className='fs-sm fw-600 lh-normal'>{selectedPick.pickValue.displayText}</p>
										</>
									)}
									{selectedPick.pickValue.confidence && (
										<div className='d-flex align-items-center' style={{ gap: '1px' }}>
											{selectedPick.pickValue.confidence.map((item, index) =>
												item === '*' ? (
													<TbStarFilled key={index} color={'#E52017'} size={14} />
												) : (
													<TbStarFilled key={index} color={'#CBD5E1'} size={14} />
												)
											)}
										</div>
									)}
								</div>
							)}
						</div>
						{selectedPick.analysis ? selectedPick.analysis : 'No analysis available.'}
					</Modal.Body>
					<Modal.Footer className='border-0'>
						<Button variant='secondary' onClick={() => setShowDescriptionModal(false)}>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</>
	);
};

export default CFBDataTable;
